<!-- eslint-disable vue/singleline-html-element-content-newline -->
<template>
  <div>

    <label
        for="song-search"
        class="u-block  u-mbe-6  u-wf-600  u-text-zeta">
      Search for a song
    </label>

    <div class="u-relative">

      <input
          v-model="q"
          type="text"
          class="c-lyrics-search  u-is-full  u-mbe-12  u-ba  u-bc-silver  u-br-5  u-text-epsilon  u-text-mine-shaft"
          placeholder="e.g. Burn Baby Burn"
          id="song-search">

      <SvgSprite
          icon="search"
          class="c-lyrics-search__icon"
          aria-hidden="true"
          width="16px"
          height="16px">
      </SvgSprite>

      <button
          type="button"
          class="o-unstyled-btn  c-lyrics-search__cancel"
          :class="{ 'u-hide' : !q }"
          aria-label="Clear search"
          @click="clearSearch">

        <SvgSprite
            icon="cancel"
            aria-hidden="true"
            width="14"
            height="14">
        </SvgSprite>

      </button>

    </div>

    <p class="u-text-milli  u-text-cod-grey">

      <template v-if="q">
        Search results for <span class="u-text-red">“{{ q }}”</span>. Showing <span class="u-text-red">{{ filteredSongs.length }}</span> {{ filteredSongs.length > 1 ? 'songs' : 'song' }} out of <span class="u-text-red">{{ songs.length }}</span>.
      </template>

      <template v-else>
        There are <span class="u-text-red">{{ songs.length }}</span> songs in our archive.
      </template>

    </p>

    <table class="c-lyrics-list">
      <thead>
        <tr>
          <th class="c-lyrics-list__title">Song title</th>
          <th class="c-lyrics-list__appears">First appears on</th>
          <th class="c-lyrics-list__released">First released</th>
        </tr>
      </thead>

      <tbody>
        <tr
            v-for="song in filteredSongs"
            :key="song.id">
          <td>
            <a
                :href="song.permalink"
                class="u-wf-600">
              {{ song.title }}
            </a>

            <span
                v-if="song.original_performer"
                class="u-text-milli">
              ({{ song.original_performer }} cover)
            </span>

            <span
                v-else-if="song.project_name"
                class="u-text-milli">
              (by {{ song.project_name }})
            </span>
          </td>

          <td data-label="First appears on">
            <template v-if="!song.unreleased">
              <a :href="`/releases/${ song.appears_on_slug }/`">
                {{ song.appears_on }}
              </a>
            </template>

            <template v-else>
              {{ song.appears_on }}
            </template>
          </td>

          <td data-label="First released">
            <template v-if="song.unreleased">
              {{ song.first_released }}
            </template>

            <template v-else>
              <time :datetime="song.first_released_raw">
                {{ song.first_released }}
              </time>
            </template>
          </td>
        </tr>
      </tbody>
    </table>

  </div>
</template>

<script setup lang="ts">
    import { computed, ref } from 'vue';
    import SvgSprite from '@components/SvgSprite.vue';
    import { songs } from '@data/songs';
    import { Song } from '@type/song.types';

    const normalise = (string: string) => {
        return string.toUpperCase().trim();
    };

    // Reactive search query string
    const q = ref<string>('');

    // Normalise the search query for consistent search results
    const normalisedQ = computed(() => {
        return normalise(q.value);
    });

    // Create a search index object for faster searching
    const songIndex = computed(() => {
        return songs.map((song, index) => {

            return {
                title : normalise(song.title),
                alternative_titles : normalise(song.alternative_titles),
                songwriter : normalise(song.songwriter),
                original_performer : normalise(song.original_performer),
                project_name : normalise(song.project_name),
                song_type : normalise(song.song_type),
                appears_on : normalise(song.appears_on),
                first_released : normalise(song.first_released),
                index
            };
        });
    });

    // Filter songs based on query string
    const songIndexFiltered = computed(() => {
        const normalisedQueryString = normalisedQ.value;

        return songIndex.value.filter((song) => {
            return normalisedQ.value === ''
                || filterOnTitle(song, normalisedQueryString)
                || filterOnAlternativeTitles(song, normalisedQueryString)
                || filterOnSongwriter(song, normalisedQueryString)
                || filterOnOriginalPerformer(song, normalisedQueryString)
                || filterOnProjectName(song, normalisedQueryString)
                || filterOnSongType(song, normalisedQueryString)
                || filterOnAppearsOn(song, normalisedQueryString)
                || filterOnFirstReleased(song, normalisedQueryString);
        });
    });

    const filteredSongs = computed(() => {
        return songIndexFiltered.value.map((song) => {
            return songs[song.index];
        });
    });

    // Clear the search query
    function clearSearch () {
        q.value = '';
    }

    /**
     * Filter by the song title, will match if the string contains "ono"'
     *
     * @param song
     * @param normalisedQueryString
     */
    function filterOnTitle (song: Song, normalisedQueryString: string) {
        return song.title.indexOf(normalisedQueryString) !== -1;
    }

    /**
     * Filter by alternative/working song names
     *
     * @param song
     * @param normalisedQueryString
     */
    function filterOnAlternativeTitles (song: Song, normalisedQueryString: string) {
        return song.alternative_titles.indexOf(normalisedQueryString) !== -1;
    }

    /**
     * Filter by the songwriter name
     *
     * @param song
     * @param normalisedQueryString
     */
    function filterOnSongwriter (song: Song, normalisedQueryString: string) {
        return song.songwriter.indexOf(normalisedQueryString) !== -1;
    }

    /**
     * Filter by the original performer
     *
     * @param song
     * @param normalisedQueryString
     */
    function filterOnOriginalPerformer (song: Song, normalisedQueryString: string) {
        return song.original_performer.indexOf(normalisedQueryString) !== -1;
    }

    /**
     * Filter by the song project name e.g. solo etc
     *
     * @param song
     * @param normalisedQueryString
     */
    function filterOnProjectName (song: Song, normalisedQueryString: string) {
        return song.project_name.indexOf(normalisedQueryString) !== -1;
    }

    /**
     * Filter by the song type e.g. cover, original etc
     *
     * @param song
     * @param normalisedQueryString
     */
    function filterOnSongType (song: Song, normalisedQueryString: string) {
        return song.song_type.indexOf(normalisedQueryString) !== -1;
    }

    /**
     * Filter by releases the song appears on
     *
     * @param song
     * @param normalisedQueryString
     */
    function filterOnAppearsOn (song: Song, normalisedQueryString: string) {
        return song.appears_on.indexOf(normalisedQueryString) !== -1;
    }

    /**
     * Filter by first released data
     *
     * @param song
     * @param normalisedQueryString
     */
    function filterOnFirstReleased (song: Song, normalisedQueryString: string) {
        return song.first_released.indexOf(normalisedQueryString) !== -1;
    }
</script>
