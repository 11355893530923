<template>
  <div ref="el">

    <slot>
      <div></div>
    </slot>

  </div>
</template>

<script setup lang="ts">
    import { onMounted, onUnmounted, ref, useTemplateRef } from 'vue';
    import { IntersectionObserverProps, IntersectionObserverEmits } from '@type/IntersectionObserver.types';

    const props = withDefaults(defineProps<IntersectionObserverProps>(), {
        disconnectOnceObserved : true,
        root : null,
        rootMargin : '0px 0px 0px 0px',
        threshold : 0
    });

    const emit = defineEmits<IntersectionObserverEmits>();

    const observer = ref<IntersectionObserver | null>(null);
    const el = useTemplateRef<HTMLElement>('el');

    /**
     * Once the target element intersects we emit, this can then be
     * caught in the parent where a method can be called e.g. to lazy
     * load an image or trigger an animation
     */
    function onIntersect (event: IntersectionObserverEntry[]) {
        const entry = event.find(entry => entry.isIntersecting);

        if (entry) {
            emit('intersect', { entry, event });

            /**
             * For performance reasons we by default disconnect the
             * observer once it intersects, however there may be instances
             * for animations etc where you may wish to keep watching, in
             * this instance you can pass a prop setting
             * "disconnectOnceObserved" to false
             */
            if (props.disconnectOnceObserved && observer.value) {
                observer.value.disconnect();
            }
        } else {
            emit('leave', { event });
        }
    }

    /**
     * Set up a new Intersection Observer and observe the parent element of
     * this component. Options can be passed as props
     */
    onMounted(() => {
        observer.value = new IntersectionObserver(onIntersect, {
            root : props.root,
            rootMargin : props.rootMargin,
            threshold : props.threshold
        });

        if (observer.value && el.value) {
            observer.value.observe(el.value);
        }
    });

    /**
     * Ensure the Intersection Observer is disconnected when the component
     * is unmounted and that the value of observer is set back to null
     */
    onUnmounted(() => {
        if (observer.value) {
            observer.value.disconnect();
            observer.value = null;
        }
    });
</script>
