<template>
  <div class="o-embed-container">

    <ImgSrcset
        :src="props.src"
        :alt="props.alt"
        :width="1920"
        :height="1080">
    </ImgSrcset>

    <button
        v-if="!showIframe"
        type="button"
        class="c-play-icon-overlay  u-center-vh"
        aria-label="Click to play video"
        tabindex="0"
        @click="showIframe = true">

      <SvgSprite
          icon="play-circle"
          class="c-play-icon"
          aria-hidden="true"
          width="100px"
          height="74px">
      </SvgSprite>

    </button>

    <transition name="v-fade-050">

      <iframe
          v-if="showIframe"
          :src="iframeSrc"
          allowfullscreen>
      </iframe>

    </transition>

  </div>
</template>

<script setup lang="ts">
    import { computed, ref } from 'vue';
    import ImgSrcset from '@components/ImgSrcset.vue';
    import SvgSprite from '@components/SvgSprite.vue';
    import { VideoPlayerProps } from '@type/LazyVideoPlayer.types';

    const props = withDefaults(defineProps<VideoPlayerProps>(), {
        alt : 'Video Image'
    });

    const PROVIDER_YOUTUBE = 'youtube';
    const PROVIDER_VIMEO = 'vimeo';

    // Reactive state
    const showIframe = ref<boolean>(false);

    // Computed properties
    const iframeSrc = computed(() => {
        switch (props.provider) {
            case PROVIDER_VIMEO :
                return `https://player.vimeo.com/video/${props.videoId}?api=1&amp;autoplay=1`;

            case PROVIDER_YOUTUBE :
                return `https://www.youtube.com/embed/${props.videoId}?controls=1&amp;enablejsapi=1&amp;loop=0&amp;modestbranding=1&amp;rel=0&amp;showinfo=0&amp;iv_load_policy=3&amp;autohide=1&amp;autoplay=1`;
        }
    });
</script>
