<template>
  <IntersectionObserver
      class="u-relative"
      @intersect="imageHasIntersected">

    <transition :name="classTransition">

      <picture
          v-if="imageIntersected"
          :class="props.classPicture"
          @load.once="imageLoaded = true">

        <slot name="sources"></slot>

        <img
            ref="image"
            :class="props.classImg"
            :data-src="props.src"
            :alt="props.alt"
            :width="props.width"
            :height="props.height">

      </picture>

    </transition>

  </IntersectionObserver>
</template>

<script lang="ts" setup>
    import { ref, nextTick, useTemplateRef } from 'vue';
    import IntersectionObserver from '@components/IntersectionObserver.vue';
    import { PictureElementProps } from '@type/PictureElement.types';

    const props = withDefaults(defineProps<PictureElementProps>(), {
        alt: '',
        classPicture: 'u-absolute  u-position-inside  u-is-full  u-bs-full',
        classTransition: 'v-fade-050'
    });

    const image = useTemplateRef<HTMLImageElement>('image');
    const imageIntersected = ref(false);
    const imageLoaded = ref(false);

    /**
     * Setting `imageIntersected` to true will add the img element
     * to the DOM.
     *
     * We wait until the nextTick when the img element is in the DOM
     * and then set the src attribute with the data-src value and
     * finally remove the data-src attribute.
     *
     * This extra step is required to workaround a nasty bug in
     * Safari where both the src and source image are downloaded,
     * defeating the purpose of lazy loading. All other browsers work
     * correctly and this can be removed should Safari ever fix this
     * issue.
     *
     * @see https://github.com/vuejs/vue/issues/11357
     */
    const imageHasIntersected = () => {
        imageIntersected.value = true;

        nextTick(() => {
            if (image.value) {
                image.value.src = image.value.dataset.src || '';
                image.value.removeAttribute('data-src');
            }
        });
    };
</script>
