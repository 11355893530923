import { Song } from '@type/song.types';

export const songs: Song[] = [
    {
        'id' : 0,
        'title' : '(Don’t Call Me) Mrs Christmas',
        'alternative_titles' : '',
        'permalink' : '/lyrics/dont-call-me-mrs-christmas/',
        'songwriter' : 'Lyrics & Music: Emma-Lee Moss & Tim Wheeler',
        'original_performer' : '',
        'project_name' : 'Emmy the Great & Tim Wheeler',
        'song_type' : 'Original',
        'appears_on' : 'This Is Christmas',
        'appears_on_slug' : 'solo/this-is-christmas',
        'first_released' : 'November 21, 2011',
        'first_released_raw' : '2011-11-21',
        'unreleased' : false
    },
    {
        'id' : 1,
        'title' : '(Don’t Fear) The Reaper',
        'alternative_titles' : '',
        'permalink' : '/lyrics/dont-fear-the-reaper/',
        'songwriter' : 'Lyrics & Music: Buck Dharma',
        'original_performer' : 'Blue Öyster Cult',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 2,
        'title' : '13th Floor',
        'alternative_titles' : '',
        'permalink' : '/lyrics/13th-floor/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Burn Baby Burn - Single',
        'appears_on_slug' : 'singles/burn-baby-burn',
        'first_released' : 'April 2, 2001',
        'first_released_raw' : '2001-04-02',
        'unreleased' : false
    },
    {
        'id' : 3,
        'title' : '5am Eternal',
        'alternative_titles' : '',
        'permalink' : '/lyrics/5am-eternal/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Angel Interceptor - Single',
        'appears_on_slug' : 'singles/angel-interceptor',
        'first_released' : 'October 9, 1995',
        'first_released_raw' : '1995-10-09',
        'unreleased' : false
    },
    {
        'id' : 4,
        'title' : 'A Clear Invitation to Dance',
        'alternative_titles' : '',
        'permalink' : '/lyrics/a-clear-invitation-to-the-dance/',
        'songwriter' : 'Lyrics & Music: Ash & Leif Bodnarchuk',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Live at the Wireless',
        'appears_on_slug' : 'compilations/live-at-the-wireless',
        'first_released' : 'February 17, 1997',
        'first_released_raw' : '1997-02-17',
        'unreleased' : false
    },
    {
        'id' : 5,
        'title' : 'A Life Less Ordinary',
        'alternative_titles' : 'Film Song',
        'permalink' : '/lyrics/a-life-less-ordinary/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'A Life Less Ordinary - Single',
        'appears_on_slug' : 'singles/a-life-less-ordinary',
        'first_released' : 'October 27, 1997',
        'first_released_raw' : '1997-10-27',
        'unreleased' : false
    },
    {
        'id' : 6,
        'title' : 'A Sign',
        'alternative_titles' : '',
        'permalink' : '/lyrics/a-sign/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley & Michael Lovett',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'True Love',
        'appears_on_slug' : 'solo/true-love',
        'first_released' : 'May 16, 2017',
        'first_released_raw' : '2017-05-16',
        'unreleased' : false
    },
    {
        'id' : 7,
        'title' : 'A Song',
        'alternative_titles' : '',
        'permalink' : '/lyrics/a-song/',
        'songwriter' : 'Lyrics: Ash',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Home Demo',
        'appears_on_slug' : 'demos/home-demo',
        'first_released' : 'November 1, 1992',
        'first_released_raw' : '1992-11-01',
        'unreleased' : false
    },
    {
        'id' : 8,
        'title' : 'Accident of Love',
        'alternative_titles' : '',
        'permalink' : '/lyrics/accident-of-love/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'True Love',
        'appears_on_slug' : 'solo/true-love',
        'first_released' : 'May 16, 2017',
        'first_released_raw' : '2017-05-16',
        'unreleased' : false
    },
    {
        'id' : 9,
        'title' : 'After Hours',
        'alternative_titles' : '',
        'permalink' : '/lyrics/after-hours/',
        'songwriter' : 'Lyrics & Music: We Are Scientists',
        'original_performer' : 'We Are Scientists',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 10,
        'title' : 'Again',
        'alternative_titles' : '',
        'permalink' : '/lyrics/again/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'The Deep Blue',
        'appears_on_slug' : 'solo/the-deep-blue',
        'first_released' : 'March 5, 2007',
        'first_released_raw' : '2007-03-05',
        'unreleased' : false
    },
    {
        'id' : 11,
        'title' : 'Alexander',
        'alternative_titles' : '',
        'permalink' : '/lyrics/alexander/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'New Worlds',
        'appears_on_slug' : 'solo/new-worlds',
        'first_released' : 'October 16, 2009',
        'first_released_raw' : '2009-10-16',
        'unreleased' : false
    },
    {
        'id' : 12,
        'title' : 'All I Want for Christmas Is You',
        'alternative_titles' : '',
        'permalink' : '/lyrics/all-i-want-for-christmas-is-you/',
        'songwriter' : 'Lyrics & Music: Mariah Carey and Walter Afanasieff',
        'original_performer' : 'Mariah Carey',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 13,
        'title' : 'All That I Have Left',
        'alternative_titles' : '',
        'permalink' : '/lyrics/all-that-i-have-left/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Islands',
        'appears_on_slug' : 'albums/islands',
        'first_released' : 'May 18, 2018',
        'first_released_raw' : '2018-05-18',
        'unreleased' : false
    },
    {
        'id' : 14,
        'title' : 'Alternative Ulster',
        'alternative_titles' : '',
        'permalink' : '/lyrics/alternative-ulster/',
        'songwriter' : 'Lyrics & Music: Gordon Ogilvie & Jake Burns',
        'original_performer' : 'Stiff Little Fingers',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 15,
        'title' : 'Always the Sun',
        'alternative_titles' : '',
        'permalink' : '/lyrics/always-the-sun/',
        'songwriter' : 'Lyrics & Music: The Stranglers',
        'original_performer' : 'The Stranglers',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 16,
        'title' : 'American Devil',
        'alternative_titles' : '',
        'permalink' : '/lyrics/american-devil/',
        'songwriter' : 'Lyrics: Gareth Hutchinson. Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'BBC Sessions 1994-1999',
        'appears_on_slug' : 'compilations/bbc-sessions-1994-1999',
        'first_released' : 'June 29, 1992',
        'first_released_raw' : '1992-06-29',
        'unreleased' : false
    },
    {
        'id' : 17,
        'title' : 'Angel Interceptor',
        'alternative_titles' : '',
        'permalink' : '/lyrics/angel-interceptor/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler & Rick McMurray',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : '1977',
        'appears_on_slug' : 'albums/1977',
        'first_released' : 'October 9, 1995',
        'first_released_raw' : '1995-10-09',
        'unreleased' : false
    },
    {
        'id' : 18,
        'title' : 'Annabel',
        'alternative_titles' : '',
        'permalink' : '/lyrics/annabel/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Islands',
        'appears_on_slug' : 'albums/islands',
        'first_released' : 'April 10, 2018',
        'first_released_raw' : '2018-04-10',
        'unreleased' : false
    },
    {
        'id' : 19,
        'title' : 'Another Girl Another Planet',
        'alternative_titles' : '',
        'permalink' : '/lyrics/another-girl-another-planet/',
        'songwriter' : 'Lyrics & Music: Peter Perrett',
        'original_performer' : 'The Only Ones',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 20,
        'title' : 'Anything Is Possible',
        'alternative_titles' : '',
        'permalink' : '/lyrics/anything-is-possible/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Kablammo!',
        'appears_on_slug' : 'albums/kablammo',
        'first_released' : 'May 25, 2015',
        'first_released_raw' : '2015-05-25',
        'unreleased' : false
    },
    {
        'id' : 21,
        'title' : 'Aphrodite',
        'alternative_titles' : '',
        'permalink' : '/lyrics/aphrodite/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Nu-Clear Sounds',
        'appears_on_slug' : 'albums/nu-clear-sounds',
        'first_released' : 'October 5, 1998',
        'first_released_raw' : '1998-10-05',
        'unreleased' : false
    },
    {
        'id' : 22,
        'title' : 'Arcadia',
        'alternative_titles' : '',
        'permalink' : '/lyrics/arcadia/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Arcadia - Single',
        'appears_on_slug' : 'a-z-singles-series/arcadia',
        'first_released' : 'November 9, 2009',
        'first_released_raw' : '2009-11-09',
        'unreleased' : false
    },
    {
        'id' : 23,
        'title' : 'Ariadna',
        'alternative_titles' : '',
        'permalink' : '/lyrics/ariadna/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : 'Tim Wheeler',
        'song_type' : 'Original',
        'appears_on' : 'Sheltered Youth - EP',
        'appears_on_slug' : 'solo/sheltered-youth',
        'first_released' : 'November 10, 2014',
        'first_released_raw' : '2014-11-10',
        'unreleased' : false
    },
    {
        'id' : 24,
        'title' : 'Astral Conversations with Toulouse Lautrec',
        'alternative_titles' : '',
        'permalink' : '/lyrics/astral-conversations-with-toulouse-lautrec/',
        'songwriter' : 'Lyrics & Music: Ash',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Girl From Mars - Single',
        'appears_on_slug' : 'singles/girl-from-mars',
        'first_released' : 'October 9, 1995',
        'first_released_raw' : '1995-10-09',
        'unreleased' : false
    },
    {
        'id' : 25,
        'title' : 'Bad Karma Blues',
        'alternative_titles' : '',
        'permalink' : '/lyrics/bad-karma-blues/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Envy - Single',
        'appears_on_slug' : 'singles/envy',
        'first_released' : 'August 26, 2002',
        'first_released_raw' : '2002-08-26',
        'unreleased' : false
    },
    {
        'id' : 26,
        'title' : 'Bastardo',
        'alternative_titles' : '',
        'permalink' : '/lyrics/bastardo/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'Grey Will Fade',
        'appears_on_slug' : 'solo/grey-will-fade',
        'first_released' : 'August 16, 2004',
        'first_released_raw' : '2004-08-16',
        'unreleased' : false
    },
    {
        'id' : 27,
        'title' : 'Be My Baby',
        'alternative_titles' : '',
        'permalink' : '/lyrics/be-my-baby/',
        'songwriter' : 'Lyrics & Music: Jeff Barry, Ellie Greenwich and Phil Spector',
        'original_performer' : 'The Ronettes',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 28,
        'title' : 'Be Thankful',
        'alternative_titles' : '',
        'permalink' : '/lyrics/be-thankful/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'The Deep Blue',
        'appears_on_slug' : 'solo/the-deep-blue',
        'first_released' : 'March 5, 2007',
        'first_released_raw' : '2007-03-05',
        'unreleased' : false
    },
    {
        'id' : 29,
        'title' : 'Behave',
        'alternative_titles' : '',
        'permalink' : '/lyrics/behave/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'The Deep Blue',
        'appears_on_slug' : 'solo/the-deep-blue',
        'first_released' : 'March 5, 2007',
        'first_released_raw' : '2007-03-05',
        'unreleased' : false
    },
    {
        'id' : 30,
        'title' : 'Big Decision',
        'alternative_titles' : '',
        'permalink' : '/lyrics/big-decision/',
        'songwriter' : 'Lyrics & Music: John O’Neill',
        'original_performer' : 'That Petrol Emotion',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 31,
        'title' : 'Big Time',
        'alternative_titles' : '',
        'permalink' : '/lyrics/big-time/',
        'songwriter' : 'Lyrics & Music: Rudi',
        'original_performer' : 'Rudi',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 32,
        'title' : 'Binary',
        'alternative_titles' : '',
        'permalink' : '/lyrics/binary/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Binary - Single',
        'appears_on_slug' : 'a-z-singles-series/binary',
        'first_released' : 'May 24, 2010',
        'first_released_raw' : '2010-05-24',
        'unreleased' : false
    },
    {
        'id' : 33,
        'title' : 'Bird Dream of the Olympus Mons',
        'alternative_titles' : '',
        'permalink' : '/lyrics/bird-dream-of-the-olympus-mons/',
        'songwriter' : 'Lyrics & Music: Black Francis',
        'original_performer' : 'Pixies',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 34,
        'title' : 'Blacklisted',
        'alternative_titles' : '',
        'permalink' : '/lyrics/blacklisted/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Twilight of the Innocents',
        'appears_on_slug' : 'albums/twilight-of-the-innocents',
        'first_released' : 'July 2, 2007',
        'first_released_raw' : '2007-07-02',
        'unreleased' : false
    },
    {
        'id' : 35,
        'title' : 'Blew',
        'alternative_titles' : '',
        'permalink' : '/lyrics/blew/',
        'songwriter' : 'Lyrics & Music: Kurdt Kobain',
        'original_performer' : 'Nirvana',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Numbskull - EP',
        'appears_on_slug' : 'eps/numbskull',
        'first_released' : 'April 26, 1999',
        'first_released_raw' : '1999-04-26',
        'unreleased' : false
    },
    {
        'id' : 36,
        'title' : 'Blue Blue',
        'alternative_titles' : '',
        'permalink' : '/lyrics/blue-blue/',
        'songwriter' : 'Lyrics & Music: Ash',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 37,
        'title' : 'Bone Machine',
        'alternative_titles' : '',
        'permalink' : '/lyrics/bone-machine/',
        'songwriter' : 'Lyrics & Music: Black Francis',
        'original_performer' : 'Pixies',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 38,
        'title' : 'Braindead',
        'alternative_titles' : '',
        'permalink' : '/lyrics/braindead/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Race the Night',
        'appears_on_slug' : 'albums/race-the-night',
        'first_released' : 'September 15, 2023',
        'first_released_raw' : '2023-09-15',
        'unreleased' : false
    },
    {
        'id' : 39,
        'title' : 'Breed',
        'alternative_titles' : '',
        'permalink' : '/lyrics/breed/',
        'songwriter' : 'Lyrics & Music: Kurt Cobain',
        'original_performer' : 'Nirvana',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 40,
        'title' : 'Bring Back the Summer',
        'alternative_titles' : '',
        'permalink' : '/lyrics/bring-back-the-summer/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Kablammo!',
        'appears_on_slug' : 'albums/kablammo',
        'first_released' : 'May 25, 2015',
        'first_released_raw' : '2015-05-25',
        'unreleased' : false
    },
    {
        'id' : 41,
        'title' : 'Buckle',
        'alternative_titles' : '',
        'permalink' : '/lyrics/buckle/',
        'songwriter' : 'Lyrics & Music: We Are Scientists',
        'original_performer' : 'We Are Scientists',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 42,
        'title' : 'Burn Baby Burn',
        'alternative_titles' : 'Ice Hat, This Is Slow Suicide, Slow Suicide',
        'permalink' : '/lyrics/burn-baby-burn/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Free All Angels',
        'appears_on_slug' : 'albums/free-all-angels',
        'first_released' : 'April 2, 2001',
        'first_released_raw' : '2001-04-02',
        'unreleased' : false
    },
    {
        'id' : 43,
        'title' : 'Burn Out',
        'alternative_titles' : 'Jaz Odyssey',
        'permalink' : '/lyrics/burn-out/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Nu-Clear Sounds',
        'appears_on_slug' : 'albums/nu-clear-sounds',
        'first_released' : 'October 5, 1998',
        'first_released_raw' : '1998-10-05',
        'unreleased' : false
    },
    {
        'id' : 44,
        'title' : 'Buzzkill',
        'alternative_titles' : '',
        'permalink' : '/lyrics/buzzkill/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Islands',
        'appears_on_slug' : 'albums/islands',
        'first_released' : 'February 8, 2018',
        'first_released_raw' : '2018-02-08',
        'unreleased' : false
    },
    {
        'id' : 45,
        'title' : 'Cactus',
        'alternative_titles' : '',
        'permalink' : '/lyrics/cactus/',
        'songwriter' : 'Lyrics & Music: Black Francis',
        'original_performer' : 'Pixies',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 46,
        'title' : 'Candy',
        'alternative_titles' : '',
        'permalink' : '/lyrics/candy/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Free All Angels',
        'appears_on_slug' : 'albums/free-all-angels',
        'first_released' : 'April 23, 2001',
        'first_released_raw' : '2001-04-23',
        'unreleased' : false
    },
    {
        'id' : 47,
        'title' : 'Candy (French)',
        'alternative_titles' : '',
        'permalink' : '/lyrics/candy-french/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Candy - Single',
        'appears_on_slug' : 'singles/candy',
        'first_released' : 'October 1, 2001',
        'first_released_raw' : '2001-10-01',
        'unreleased' : false
    },
    {
        'id' : 48,
        'title' : 'Cantina Band',
        'alternative_titles' : '',
        'permalink' : '/lyrics/cantina-band/',
        'songwriter' : 'Composed by: John Williams',
        'original_performer' : 'John Williams',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Girl From Mars - Single',
        'appears_on_slug' : 'singles/girl-from-mars',
        'first_released' : 'March 20, 1995',
        'first_released_raw' : '1995-03-20',
        'unreleased' : false
    },
    {
        'id' : 49,
        'title' : 'Carlos D',
        'alternative_titles' : '',
        'permalink' : '/lyrics/carlos-d/',
        'songwriter' : 'Lyrics & Music: Ash',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 50,
        'title' : 'Carnal Love',
        'alternative_titles' : '',
        'permalink' : '/lyrics/carnal-love/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Carnal Love - Single',
        'appears_on_slug' : 'a-z-singles-series/carnal-love',
        'first_released' : 'August 2, 2010',
        'first_released_raw' : '2010-08-02',
        'unreleased' : false
    },
    {
        'id' : 51,
        'title' : 'Cecilia Ann',
        'alternative_titles' : '',
        'permalink' : '/lyrics/cecilia-ann/',
        'songwriter' : 'Music: The Surftones',
        'original_performer' : 'The Surftones',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 52,
        'title' : 'Change Your Name',
        'alternative_titles' : '',
        'permalink' : '/lyrics/change-your-name/',
        'songwriter' : 'Lyrics & Music: Rick McMurray',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Change Your Name - Single',
        'appears_on_slug' : 'a-z-singles-series/change-your-name',
        'first_released' : 'August 30, 2010',
        'first_released_raw' : '2010-08-30',
        'unreleased' : false
    },
    {
        'id' : 53,
        'title' : 'Cherry Bomb',
        'alternative_titles' : '',
        'permalink' : '/lyrics/cherry-bomb/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Free All Angels',
        'appears_on_slug' : 'albums/free-all-angels',
        'first_released' : 'April 23, 2001',
        'first_released_raw' : '2001-04-23',
        'unreleased' : false
    },
    {
        'id' : 54,
        'title' : 'Chinese New Year',
        'alternative_titles' : '',
        'permalink' : '/lyrics/chinese-new-year/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Polaris - Single',
        'appears_on_slug' : 'singles/polaris',
        'first_released' : 'June 18, 2007',
        'first_released_raw' : '2007-06-18',
        'unreleased' : false
    },
    {
        'id' : 55,
        'title' : 'Chorus Pedal',
        'alternative_titles' : '',
        'permalink' : '/lyrics/chorus-pedal/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler & Mark Hamilton',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Shed - Demo tape',
        'appears_on_slug' : 'demos/shed',
        'first_released' : 'September 1, 1992',
        'first_released_raw' : '1992-09-01',
        'unreleased' : false
    },
    {
        'id' : 56,
        'title' : 'Christine',
        'alternative_titles' : '',
        'permalink' : '/lyrics/christine/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler & Mark Hamilton',
        'original_performer' : '',
        'project_name' : 'Vietnam',
        'song_type' : 'Original',
        'appears_on' : 'Vietnam - Demo tape',
        'appears_on_slug' : 'demos/vietnam',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 57,
        'title' : 'Christmas Day (I Wish I Was Surfing)',
        'alternative_titles' : '',
        'permalink' : '/lyrics/christmas-day-i-wish-i-was-surfing/',
        'songwriter' : 'Lyrics & Music: Emma-Lee Moss & Tim Wheeler',
        'original_performer' : '',
        'project_name' : 'Emmy the Great & Tim Wheeler',
        'song_type' : 'Original',
        'appears_on' : 'This Is Christmas',
        'appears_on_slug' : 'solo/this-is-christmas',
        'first_released' : 'November 21, 2011',
        'first_released_raw' : '2011-11-21',
        'unreleased' : false
    },
    {
        'id' : 58,
        'title' : 'Christmas in Prison',
        'alternative_titles' : '',
        'permalink' : '/lyrics/christmas-in-prison/',
        'songwriter' : 'Lyrics & Music: John Prine',
        'original_performer' : 'John Prine',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 59,
        'title' : 'Christmas Moon',
        'alternative_titles' : '',
        'permalink' : '/lyrics/christmas-moon/',
        'songwriter' : 'Lyrics & Music: Emma-Lee Moss & Tim Wheeler',
        'original_performer' : '',
        'project_name' : 'Emmy the Great & Tim Wheeler',
        'song_type' : 'Original',
        'appears_on' : 'This Is Christmas',
        'appears_on_slug' : 'solo/this-is-christmas',
        'first_released' : 'November 21, 2011',
        'first_released_raw' : '2011-11-21',
        'unreleased' : false
    },
    {
        'id' : 60,
        'title' : 'Cinnabar',
        'alternative_titles' : '',
        'permalink' : '/lyrics/cinnabar/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'New Worlds',
        'appears_on_slug' : 'solo/new-worlds',
        'first_released' : 'October 16, 2009',
        'first_released_raw' : '2009-10-16',
        'unreleased' : false
    },
    {
        'id' : 61,
        'title' : 'Clones',
        'alternative_titles' : '',
        'permalink' : '/lyrics/clones/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Meltdown',
        'appears_on_slug' : 'albums/meltdown',
        'first_released' : 'February 25, 2004',
        'first_released_raw' : '2004-02-25',
        'unreleased' : false
    },
    {
        'id' : 62,
        'title' : 'Coasting',
        'alternative_titles' : '',
        'permalink' : '/lyrics/coasting/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'There’s a Star - Single',
        'appears_on_slug' : 'singles/theres-a-star',
        'first_released' : 'December 31, 2001',
        'first_released_raw' : '2001-12-31',
        'unreleased' : false
    },
    {
        'id' : 63,
        'title' : 'Cocoon',
        'alternative_titles' : '',
        'permalink' : '/lyrics/cocoon/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Kablammo!',
        'appears_on_slug' : 'albums/kablammo',
        'first_released' : 'February 18, 2015',
        'first_released_raw' : '2015-02-18',
        'unreleased' : false
    },
    {
        'id' : 64,
        'title' : 'Colours',
        'alternative_titles' : '',
        'permalink' : '/lyrics/colours/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'New Worlds',
        'appears_on_slug' : 'solo/new-worlds',
        'first_released' : 'October 16, 2009',
        'first_released_raw' : '2009-10-16',
        'unreleased' : false
    },
    {
        'id' : 65,
        'title' : 'Come on Over',
        'alternative_titles' : '',
        'permalink' : '/lyrics/come-on-over/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Polaris - Single',
        'appears_on_slug' : 'singles/polaris',
        'first_released' : 'June 18, 2007',
        'first_released_raw' : '2007-06-18',
        'unreleased' : false
    },
    {
        'id' : 66,
        'title' : 'Comet Tempel 1',
        'alternative_titles' : 'What is the Meaning?',
        'permalink' : '/lyrics/comet-tempel-1/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'You Can’t Have It All - Single',
        'appears_on_slug' : 'singles/you-cant-have-it-all',
        'first_released' : 'April 16, 2007',
        'first_released_raw' : '2007-04-16',
        'unreleased' : false
    },
    {
        'id' : 67,
        'title' : 'Coming Around Again',
        'alternative_titles' : '',
        'permalink' : '/lyrics/coming-around-again/',
        'songwriter' : 'Lyrics & Music: Carly Simon',
        'original_performer' : 'Carly Simon',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'A-Z Vol.1',
        'appears_on_slug' : 'compilations/a-z-vol-1',
        'first_released' : 'April 19, 2010',
        'first_released_raw' : '2010-04-19',
        'unreleased' : false
    },
    {
        'id' : 68,
        'title' : 'Command',
        'alternative_titles' : '',
        'permalink' : '/lyrics/command/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Command - Single',
        'appears_on_slug' : 'a-z-singles-series/command',
        'first_released' : 'February 15, 2010',
        'first_released_raw' : '2010-02-15',
        'unreleased' : false
    },
    {
        'id' : 69,
        'title' : 'Confessions in the Pool',
        'alternative_titles' : '',
        'permalink' : '/lyrics/confessions-in-the-pool/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Islands',
        'appears_on_slug' : 'albums/islands',
        'first_released' : 'May 11, 2018',
        'first_released_raw' : '2018-05-11',
        'unreleased' : false
    },
    {
        'id' : 70,
        'title' : 'Cool It Down',
        'alternative_titles' : '',
        'permalink' : '/lyrics/cool-it-down/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Starcrossed - Single',
        'appears_on_slug' : 'singles/starcrossed',
        'first_released' : 'July 19, 2004',
        'first_released_raw' : '2004-07-19',
        'unreleased' : false
    },
    {
        'id' : 71,
        'title' : 'Cousteau',
        'alternative_titles' : '',
        'permalink' : '/lyrics/cousteau/',
        'songwriter' : 'Music: Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'The Deep Blue',
        'appears_on_slug' : 'solo/the-deep-blue',
        'first_released' : 'March 5, 2007',
        'first_released_raw' : '2007-03-05',
        'unreleased' : false
    },
    {
        'id' : 72,
        'title' : 'Crashed Out Wasted',
        'alternative_titles' : '',
        'permalink' : '/lyrics/crashed-out-wasted/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Race the Night',
        'appears_on_slug' : 'albums/race-the-night',
        'first_released' : 'August 30, 2023',
        'first_released_raw' : '2023-08-30',
        'unreleased' : false
    },
    {
        'id' : 73,
        'title' : 'Cruelty Street Contest',
        'alternative_titles' : '',
        'permalink' : '/lyrics/cruelty-street-contest/',
        'songwriter' : 'Lyrics: Tim Wheeler. Music: Tim Wheeler, Mark Hamilton & Rick McMurray',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Shed - Demo tape',
        'appears_on_slug' : 'demos/shed',
        'first_released' : 'September 1, 1992',
        'first_released_raw' : '1992-09-01',
        'unreleased' : false
    },
    {
        'id' : 74,
        'title' : 'CTRL-ALT-DEL',
        'alternative_titles' : '',
        'permalink' : '/lyrics/ctrl-alt-del/',
        'songwriter' : 'Lyrics & Music: Rick McMurray',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'A-Z Vol.1',
        'appears_on_slug' : 'compilations/a-z-vol-1',
        'first_released' : 'April 19, 2010',
        'first_released_raw' : '2010-04-19',
        'unreleased' : false
    },
    {
        'id' : 75,
        'title' : 'D&B',
        'alternative_titles' : '',
        'permalink' : '/lyrics/d-b/',
        'songwriter' : 'Lyrics & Music: Ash',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 76,
        'title' : 'Daedalus',
        'alternative_titles' : '',
        'permalink' : '/lyrics/daedalus/',
        'songwriter' : 'Lyrics & Music: Ash',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 77,
        'title' : 'Dancing on the Moon',
        'alternative_titles' : '',
        'permalink' : '/lyrics/dancing-on-the-moon/',
        'songwriter' : 'Lyrics & Music: Mark Hamilton',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Jesus Says - Single',
        'appears_on_slug' : 'singles/jesus-says',
        'first_released' : 'September 21, 1998',
        'first_released_raw' : '1998-09-21',
        'unreleased' : false
    },
    {
        'id' : 78,
        'title' : 'Dancing the Manta Ray',
        'alternative_titles' : '',
        'permalink' : '/lyrics/dancing-the-manta-ray/',
        'songwriter' : 'Lyrics & Music: Black Francis',
        'original_performer' : 'Pixies',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 79,
        'title' : 'Dare to Dream',
        'alternative_titles' : '',
        'permalink' : '/lyrics/dare-to-dream/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Dare to Dream - Single',
        'appears_on_slug' : 'a-z-singles-series/dare-to-dream',
        'first_released' : 'April 12, 2010',
        'first_released_raw' : '2010-04-12',
        'unreleased' : false
    },
    {
        'id' : 80,
        'title' : 'Dark and Stormy',
        'alternative_titles' : '',
        'permalink' : '/lyrics/dark-and-stormy/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Twilight of the Innocents',
        'appears_on_slug' : 'albums/twilight-of-the-innocents',
        'first_released' : 'July 2, 2007',
        'first_released_raw' : '2007-07-02',
        'unreleased' : false
    },
    {
        'id' : 81,
        'title' : 'Darkest Hour of the Night',
        'alternative_titles' : '',
        'permalink' : '/lyrics/darkest-hour-of-the-night/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Teenage Wildlife: 25 Years of Ash',
        'appears_on_slug' : 'compilations/teenage-wildlife',
        'first_released' : 'January 6, 2020',
        'first_released_raw' : '2020-01-06',
        'unreleased' : false
    },
    {
        'id' : 82,
        'title' : 'Darkside Lightside',
        'alternative_titles' : '',
        'permalink' : '/lyrics/darkside-lightside/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : '1977',
        'appears_on_slug' : 'albums/1977',
        'first_released' : 'May 6, 1996',
        'first_released_raw' : '1996-05-06',
        'unreleased' : false
    },
    {
        'id' : 83,
        'title' : 'Dawn Treader',
        'alternative_titles' : '',
        'permalink' : '/lyrics/dawn-treader/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley & Andy Partridge',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'The Deep Blue',
        'appears_on_slug' : 'solo/the-deep-blue',
        'first_released' : 'March 5, 2007',
        'first_released_raw' : '2007-03-05',
        'unreleased' : false
    },
    {
        'id' : 84,
        'title' : 'Day of the Triffids',
        'alternative_titles' : '',
        'permalink' : '/lyrics/day-of-the-triffids/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Kung Fu - Single',
        'appears_on_slug' : 'singles/kung-fu',
        'first_released' : 'March 20, 1995',
        'first_released_raw' : '1995-03-20',
        'unreleased' : false
    },
    {
        'id' : 85,
        'title' : 'Daydream Believer',
        'alternative_titles' : '',
        'permalink' : '/lyrics/daydream-believer/',
        'songwriter' : 'Lyrics & Music: John Stewart',
        'original_performer' : 'The Monkees',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 86,
        'title' : 'Dead Fairy',
        'alternative_titles' : '',
        'permalink' : '/lyrics/dead-fairy/',
        'songwriter' : 'Lyrics & Music: Ash',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 87,
        'title' : 'Death Trip 21',
        'alternative_titles' : 'Zep / Stooge',
        'permalink' : '/lyrics/death-trip-21/',
        'songwriter' : 'Lyrics: Tim Wheeler. Music: Tim Wheeler & Mark Hamilton',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Nu-Clear Sounds',
        'appears_on_slug' : 'albums/nu-clear-sounds',
        'first_released' : 'October 5, 1998',
        'first_released_raw' : '1998-10-05',
        'unreleased' : false
    },
    {
        'id' : 88,
        'title' : 'Debaser',
        'alternative_titles' : '',
        'permalink' : '/lyrics/debaser/',
        'songwriter' : 'Lyrics & Music: Black Francis',
        'original_performer' : 'Pixies',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 89,
        'title' : 'Detonator',
        'alternative_titles' : '',
        'permalink' : '/lyrics/detonator/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Meltdown',
        'appears_on_slug' : 'albums/meltdown',
        'first_released' : 'May 17, 2004',
        'first_released_raw' : '2004-05-17',
        'unreleased' : false
    },
    {
        'id' : 90,
        'title' : 'Devil’s Haircut',
        'alternative_titles' : '',
        'permalink' : '/lyrics/devils-haircut/',
        'songwriter' : 'Words: Brian Bell',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Barbie - Single',
        'appears_on_slug' : 'singles/barbie',
        'first_released' : 'March 3, 1997',
        'first_released_raw' : '1997-03-03',
        'unreleased' : false
    },
    {
        'id' : 91,
        'title' : 'Diamond Rick',
        'alternative_titles' : '',
        'permalink' : '/lyrics/diamond-rick/',
        'songwriter' : 'Lyrics & Music: Ash',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 92,
        'title' : 'Did Your Love Burn Out?',
        'alternative_titles' : '',
        'permalink' : '/lyrics/did-your-love-burn-out/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Islands',
        'appears_on_slug' : 'albums/islands',
        'first_released' : 'May 18, 2018',
        'first_released_raw' : '2018-05-18',
        'unreleased' : false
    },
    {
        'id' : 93,
        'title' : 'Different Today',
        'alternative_titles' : '',
        'permalink' : '/lyrics/different-today/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Uncle Pat - Single',
        'appears_on_slug' : 'singles/uncle-pat',
        'first_released' : 'October 17, 1994',
        'first_released_raw' : '1994-10-17',
        'unreleased' : false
    },
    {
        'id' : 94,
        'title' : 'Dionysian Urge',
        'alternative_titles' : '',
        'permalink' : '/lyrics/dionysian-urge/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Dionysian Urge - Single',
        'appears_on_slug' : 'a-z-singles-series/dionysian-urge',
        'first_released' : 'March 15, 2010',
        'first_released_raw' : '2010-03-15',
        'unreleased' : false
    },
    {
        'id' : 95,
        'title' : 'Dirty Old Town',
        'alternative_titles' : '',
        'permalink' : '/lyrics/dirty-old-town/',
        'songwriter' : 'Lyrics & Music: Ewan MacColl',
        'original_performer' : 'Ewan MacColl',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 96,
        'title' : 'Disenchanted',
        'alternative_titles' : '',
        'permalink' : '/lyrics/disenchanted/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'A-Z Vol.1',
        'appears_on_slug' : 'compilations/a-z-vol-1',
        'first_released' : 'April 7, 2010',
        'first_released_raw' : '2010-04-07',
        'unreleased' : false
    },
    {
        'id' : 97,
        'title' : 'Dispatch',
        'alternative_titles' : '',
        'permalink' : '/lyrics/dispatch/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Kablammo!',
        'appears_on_slug' : 'albums/kablammo',
        'first_released' : 'May 25, 2015',
        'first_released_raw' : '2015-05-25',
        'unreleased' : false
    },
    {
        'id' : 98,
        'title' : 'Do You Ever Think of Me?',
        'alternative_titles' : '',
        'permalink' : '/lyrics/do-you-ever-think-of-me/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : 'Tim Wheeler',
        'song_type' : 'Original',
        'appears_on' : 'Lost Domain',
        'appears_on_slug' : 'solo/lost-domain',
        'first_released' : 'November 10, 2014',
        'first_released_raw' : '2014-11-10',
        'unreleased' : false
    },
    {
        'id' : 99,
        'title' : 'Do You Feel It?',
        'alternative_titles' : '',
        'permalink' : '/lyrics/do-you-feel-it/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'A-Z Vol.1',
        'appears_on_slug' : 'compilations/a-z-vol-1',
        'first_released' : 'April 19, 2010',
        'first_released_raw' : '2010-04-19',
        'unreleased' : false
    },
    {
        'id' : 100,
        'title' : 'Do You Wanna Dance?',
        'alternative_titles' : '',
        'permalink' : '/lyrics/do-you-wanna-dance/',
        'songwriter' : 'Lyrics & Music: Bobby Freeman',
        'original_performer' : 'Bobby Freeman',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Little Infinity - EP',
        'appears_on_slug' : 'eps/little-infinity',
        'first_released' : 'June 16, 2012',
        'first_released_raw' : '2012-06-16',
        'unreleased' : false
    },
    {
        'id' : 101,
        'title' : 'Does Your Mother Know?',
        'alternative_titles' : '',
        'permalink' : '/lyrics/does-your-mother-know/',
        'songwriter' : 'Lyrics & Music: Benny Anderson & Bjorn Ulvaeus',
        'original_performer' : 'ABBA',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Oh Yeah - Single',
        'appears_on_slug' : 'singles/oh-yeah',
        'first_released' : 'June 24, 1996',
        'first_released_raw' : '1996-06-24',
        'unreleased' : false
    },
    {
        'id' : 102,
        'title' : 'Don’t Know',
        'alternative_titles' : '',
        'permalink' : '/lyrics/dont-know/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Jack Names the Planets - Single',
        'appears_on_slug' : 'singles/jack-names-the-planets',
        'first_released' : 'February 7, 1994',
        'first_released_raw' : '1994-02-07',
        'unreleased' : false
    },
    {
        'id' : 103,
        'title' : 'Don’t Let Me Down',
        'alternative_titles' : '',
        'permalink' : '/lyrics/dont-let-me-down/',
        'songwriter' : 'Lyrics & Music: John Lennon and Paul McCartney',
        'original_performer' : 'The Beatles',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 104,
        'title' : 'Don’t Need Your Love',
        'alternative_titles' : '',
        'permalink' : '/lyrics/dont-need-your-love/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Islands',
        'appears_on_slug' : 'albums/islands',
        'first_released' : 'May 18, 2018',
        'first_released_raw' : '2018-05-18',
        'unreleased' : false
    },
    {
        'id' : 105,
        'title' : 'Don’t Talk to Them',
        'alternative_titles' : '',
        'permalink' : '/lyrics/dont-talk-to-them/',
        'songwriter' : 'Lyrics: Tim Wheeler. Music: Tim Wheeler & Mark Hamilton',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Garage Girl - Demo tape',
        'appears_on_slug' : 'demos/garage-girl',
        'first_released' : 'February 1, 1993',
        'first_released_raw' : '1993-02-01',
        'unreleased' : false
    },
    {
        'id' : 106,
        'title' : 'Double Dare',
        'alternative_titles' : '',
        'permalink' : '/lyrics/double-dare/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Race the Night',
        'appears_on_slug' : 'albums/race-the-night',
        'first_released' : 'September 15, 2023',
        'first_released_raw' : '2023-09-15',
        'unreleased' : false
    },
    {
        'id' : 107,
        'title' : 'Down',
        'alternative_titles' : '',
        'permalink' : '/lyrics/down/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'Grey Will Fade',
        'appears_on_slug' : 'solo/grey-will-fade',
        'first_released' : 'August 16, 2004',
        'first_released_raw' : '2004-08-16',
        'unreleased' : false
    },
    {
        'id' : 108,
        'title' : 'Dumb Luck',
        'alternative_titles' : '',
        'permalink' : '/lyrics/dumb-luck/',
        'songwriter' : 'Lyrics & Music: We Are Scientists',
        'original_performer' : 'We Are Scientists',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 109,
        'title' : 'Easy Girl',
        'alternative_titles' : '',
        'permalink' : '/lyrics/easy-girl/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Islands',
        'appears_on_slug' : 'albums/islands',
        'first_released' : 'May 18, 2018',
        'first_released_raw' : '2018-05-18',
        'unreleased' : false
    },
    {
        'id' : 110,
        'title' : 'Embers',
        'alternative_titles' : '',
        'permalink' : '/lyrics/embers/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Embers - Single',
        'appears_on_slug' : 'a-z-singles-series/embers',
        'first_released' : 'August 16, 2010',
        'first_released_raw' : '2010-08-16',
        'unreleased' : false
    },
    {
        'id' : 111,
        'title' : 'Empire of Sand',
        'alternative_titles' : '',
        'permalink' : '/lyrics/empire-of-sand/',
        'songwriter' : 'Lyrics & Music: Rick McMurray',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Empire of Sand - Single',
        'appears_on_slug' : 'a-z-singles-series/empire-of-sand',
        'first_released' : 'March 27, 2010',
        'first_released_raw' : '2010-03-27',
        'unreleased' : false
    },
    {
        'id' : 112,
        'title' : 'End of an Era',
        'alternative_titles' : '',
        'permalink' : '/lyrics/end-of-an-era/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : 'Tim Wheeler',
        'song_type' : 'Original',
        'appears_on' : 'Lost Domain',
        'appears_on_slug' : 'solo/lost-domain',
        'first_released' : 'November 10, 2014',
        'first_released_raw' : '2014-11-10',
        'unreleased' : false
    },
    {
        'id' : 113,
        'title' : 'End of the World',
        'alternative_titles' : '',
        'permalink' : '/lyrics/end-of-the-world/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Twilight of the Innocents',
        'appears_on_slug' : 'albums/twilight-of-the-innocents',
        'first_released' : 'July 2, 2007',
        'first_released_raw' : '2007-07-02',
        'unreleased' : false
    },
    {
        'id' : 114,
        'title' : 'Envy',
        'alternative_titles' : '',
        'permalink' : '/lyrics/envy/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Envy - Single',
        'appears_on_slug' : 'singles/envy',
        'first_released' : 'August 26, 2002',
        'first_released_raw' : '2002-08-26',
        'unreleased' : false
    },
    {
        'id' : 115,
        'title' : 'Evel Knievel',
        'alternative_titles' : '',
        'permalink' : '/lyrics/evel-knievel/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Kablammo!',
        'appears_on_slug' : 'albums/kablammo',
        'first_released' : 'May 25, 2015',
        'first_released_raw' : '2015-05-25',
        'unreleased' : false
    },
    {
        'id' : 116,
        'title' : 'Everybody’s Happy Nowadays',
        'alternative_titles' : '',
        'permalink' : '/lyrics/everybodys-happy-nowadays/',
        'songwriter' : 'Lyrics & Music: Pete Shelley',
        'original_performer' : 'The Buzzcocks',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Orpheus - Single',
        'appears_on_slug' : 'singles/orpheus',
        'first_released' : 'May 3, 2004',
        'first_released_raw' : '2004-05-03',
        'unreleased' : false
    },
    {
        'id' : 117,
        'title' : 'Everywhere Is All Around',
        'alternative_titles' : '',
        'permalink' : '/lyrics/everywhere-is-all-around/',
        'songwriter' : 'Lyrics: Martin Carr. Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Oh Yeah - Single',
        'appears_on_slug' : 'singles/oh-yeah',
        'first_released' : 'June 24, 1996',
        'first_released_raw' : '1996-06-24',
        'unreleased' : false
    },
    {
        'id' : 118,
        'title' : 'Evil Eye',
        'alternative_titles' : '',
        'permalink' : '/lyrics/evil-eye/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Meltdown',
        'appears_on_slug' : 'albums/meltdown',
        'first_released' : 'May 17, 2004',
        'first_released_raw' : '2004-05-17',
        'unreleased' : false
    },
    {
        'id' : 119,
        'title' : 'Fairytale of New York',
        'alternative_titles' : '',
        'permalink' : '/lyrics/fairytale-of-new-york/',
        'songwriter' : 'Lyrics & Music: Jem Finer and Shane MacGowan',
        'original_performer' : 'The Pogues featuring Kirsty MacColl',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 120,
        'title' : 'Feel No Pain',
        'alternative_titles' : '',
        'permalink' : '/lyrics/feel-no-pain/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Shining Light - Single',
        'appears_on_slug' : 'singles/shining-light',
        'first_released' : 'January 29, 2001',
        'first_released_raw' : '2001-01-29',
        'unreleased' : false
    },
    {
        'id' : 121,
        'title' : 'Feels Like Summer',
        'alternative_titles' : '',
        'permalink' : '/lyrics/feels-like-summer/',
        'songwriter' : 'Lyrics & Music: Nick Hodgson, Ilan Eshkeri & Tim Wheeler',
        'original_performer' : '',
        'project_name' : 'Tim Wheeler',
        'song_type' : 'Original',
        'appears_on' : 'Feels Like Summer - Single',
        'appears_on_slug' : 'solo/feels-like-summer',
        'first_released' : 'February 20, 2015',
        'first_released_raw' : '2015-02-20',
        'unreleased' : false
    },
    {
        'id' : 122,
        'title' : 'Firebird',
        'alternative_titles' : '',
        'permalink' : '/lyrics/firebird/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'New Worlds',
        'appears_on_slug' : 'solo/new-worlds',
        'first_released' : 'October 16, 2009',
        'first_released_raw' : '2009-10-16',
        'unreleased' : false
    },
    {
        'id' : 123,
        'title' : 'First Sign of Spring',
        'alternative_titles' : '',
        'permalink' : '/lyrics/first-sign-of-spring/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : 'Tim Wheeler',
        'song_type' : 'Original',
        'appears_on' : 'Lost Domain',
        'appears_on_slug' : 'solo/lost-domain',
        'first_released' : 'November 10, 2014',
        'first_released_raw' : '2014-11-10',
        'unreleased' : false
    },
    {
        'id' : 124,
        'title' : 'Folk Song',
        'alternative_titles' : '',
        'permalink' : '/lyrics/folk-song/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Nu-Clear Sounds',
        'appears_on_slug' : 'albums/nu-clear-sounds',
        'first_released' : 'October 5, 1998',
        'first_released_raw' : '1998-10-05',
        'unreleased' : false
    },
    {
        'id' : 125,
        'title' : 'For Eternity',
        'alternative_titles' : '',
        'permalink' : '/lyrics/for-eternity/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Kablammo!',
        'appears_on_slug' : 'albums/kablammo',
        'first_released' : 'May 25, 2015',
        'first_released_raw' : '2015-05-25',
        'unreleased' : false
    },
    {
        'id' : 126,
        'title' : 'Forgive',
        'alternative_titles' : '',
        'permalink' : '/lyrics/forgive/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley & Michael Lovett',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'True Love',
        'appears_on_slug' : 'solo/true-love',
        'first_released' : 'May 16, 2017',
        'first_released_raw' : '2017-05-16',
        'unreleased' : false
    },
    {
        'id' : 127,
        'title' : 'Fortune Teller',
        'alternative_titles' : '',
        'permalink' : '/lyrics/fortune-teller/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Nu-Clear Sounds',
        'appears_on_slug' : 'albums/nu-clear-sounds',
        'first_released' : 'October 5, 1998',
        'first_released_raw' : '1998-10-05',
        'unreleased' : false
    },
    {
        'id' : 128,
        'title' : 'Free',
        'alternative_titles' : '',
        'permalink' : '/lyrics/free/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Kablammo!',
        'appears_on_slug' : 'albums/kablammo',
        'first_released' : 'May 25, 2015',
        'first_released_raw' : '2015-05-25',
        'unreleased' : false
    },
    {
        'id' : 129,
        'title' : 'Friends of P.',
        'alternative_titles' : '',
        'permalink' : '/lyrics/friends-of-p/',
        'songwriter' : 'Lyrics & Music: Matt Sharp',
        'original_performer' : 'The Rentals',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 130,
        'title' : 'Full Circle',
        'alternative_titles' : '',
        'permalink' : '/lyrics/full-circle/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'New Worlds',
        'appears_on_slug' : 'solo/new-worlds',
        'first_released' : 'October 16, 2009',
        'first_released_raw' : '2009-10-16',
        'unreleased' : false
    },
    {
        'id' : 131,
        'title' : 'Gabriel',
        'alternative_titles' : '',
        'permalink' : '/lyrics/gabriel/',
        'songwriter' : 'Lyrics & Music: Mark Hamilton',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Shining Light - Single',
        'appears_on_slug' : 'singles/shining-light',
        'first_released' : 'January 29, 2001',
        'first_released_raw' : '2001-01-29',
        'unreleased' : false
    },
    {
        'id' : 132,
        'title' : 'Gallows Hill',
        'alternative_titles' : 'Ashes',
        'permalink' : '/lyrics/gallows-hill/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Gallows Hill - Single',
        'appears_on_slug' : 'a-z-singles-series/gallows-hill',
        'first_released' : 'January 15, 2010',
        'first_released_raw' : '2010-01-15',
        'unreleased' : false
    },
    {
        'id' : 133,
        'title' : 'Garage Girl',
        'alternative_titles' : '',
        'permalink' : '/lyrics/garage-girl/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Garage Girl - Demo tape',
        'appears_on_slug' : 'demos/garage-girl',
        'first_released' : 'February 1, 1993',
        'first_released_raw' : '1993-02-01',
        'unreleased' : false
    },
    {
        'id' : 134,
        'title' : 'Get In',
        'alternative_titles' : '',
        'permalink' : '/lyrics/get-in/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 135,
        'title' : 'Get Out',
        'alternative_titles' : '',
        'permalink' : '/lyrics/get-out/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler & Mark Hamilton',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Trailer',
        'appears_on_slug' : 'eps/trailer',
        'first_released' : 'October 17, 1994',
        'first_released_raw' : '1994-10-17',
        'unreleased' : false
    },
    {
        'id' : 136,
        'title' : 'Get Ready',
        'alternative_titles' : '',
        'permalink' : '/lyrics/get-ready/',
        'songwriter' : 'Lyrics & Music: Smokey Robinson',
        'original_performer' : 'The Temptations',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Get Ready - Single',
        'appears_on_slug' : 'singles/get-ready',
        'first_released' : 'December 4, 1995',
        'first_released_raw' : '1995-12-04',
        'unreleased' : false
    },
    {
        'id' : 137,
        'title' : 'Ghosts',
        'alternative_titles' : '',
        'permalink' : '/lyrics/ghosts/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'You Can’t Have It All - Single',
        'appears_on_slug' : 'singles/you-cant-have-it-all',
        'first_released' : 'April 16, 2007',
        'first_released_raw' : '2007-04-16',
        'unreleased' : false
    },
    {
        'id' : 138,
        'title' : 'Gigantic',
        'alternative_titles' : '',
        'permalink' : '/lyrics/gigantic/',
        'songwriter' : 'Lyrics & Music: Kim Deal & Black Francis',
        'original_performer' : 'Pixies',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 139,
        'title' : 'Gimme Some Truth',
        'alternative_titles' : '',
        'permalink' : '/lyrics/gimme-some-truth/',
        'songwriter' : 'Lyrics & Music: John Lennon',
        'original_performer' : 'John Lennon & The Plastic Ono Band',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Angel Interceptor - Single',
        'appears_on_slug' : 'singles/angel-interceptor',
        'first_released' : 'October 9, 1995',
        'first_released_raw' : '1995-10-09',
        'unreleased' : false
    },
    {
        'id' : 140,
        'title' : 'Girl From Mars',
        'alternative_titles' : '',
        'permalink' : '/lyrics/girl-from-mars/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : '1977',
        'appears_on_slug' : 'albums/1977',
        'first_released' : 'July 31, 1995',
        'first_released_raw' : '1995-07-31',
        'unreleased' : false
    },
    {
        'id' : 141,
        'title' : 'Give Peace a Chance',
        'alternative_titles' : '',
        'permalink' : '/lyrics/give-peace-a-chance/',
        'songwriter' : 'Lyrics & Music: John Lennon',
        'original_performer' : 'Plastic Ono Band',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 142,
        'title' : 'Gloria',
        'alternative_titles' : '',
        'permalink' : '/lyrics/gloria/',
        'songwriter' : 'Lyrics: John Lee Hooker & Van Morrison. Music: Van Morrison',
        'original_performer' : 'Van Morrison',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 143,
        'title' : 'Glow',
        'alternative_titles' : '',
        'permalink' : '/lyrics/glow/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler & Arthur Baker',
        'original_performer' : '',
        'project_name' : 'Arthur Baker',
        'song_type' : 'Original',
        'appears_on' : 'Glow - Single',
        'appears_on_slug' : 'solo/glow',
        'first_released' : 'March 27, 2006',
        'first_released_raw' : '2006-03-27',
        'unreleased' : false
    },
    {
        'id' : 144,
        'title' : 'Go to Hell',
        'alternative_titles' : '',
        'permalink' : '/lyrics/go-to-hell/',
        'songwriter' : 'Lyrics & Music: Ash',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 145,
        'title' : 'Go! Fight! Win!',
        'alternative_titles' : 'Fortune',
        'permalink' : '/lyrics/go-fight-win/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Kablammo!',
        'appears_on_slug' : 'albums/kablammo',
        'first_released' : 'May 25, 2015',
        'first_released_raw' : '2015-05-25',
        'unreleased' : false
    },
    {
        'id' : 146,
        'title' : 'Goldfinger',
        'alternative_titles' : '',
        'permalink' : '/lyrics/goldfinger/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : '1977',
        'appears_on_slug' : 'albums/1977',
        'first_released' : 'April 15, 1996',
        'first_released_raw' : '1996-04-15',
        'unreleased' : false
    },
    {
        'id' : 147,
        'title' : 'Gone the Dream',
        'alternative_titles' : '',
        'permalink' : '/lyrics/gone-the-dream/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : '1977',
        'appears_on_slug' : 'albums/1977',
        'first_released' : 'May 6, 1996',
        'first_released_raw' : '1996-05-06',
        'unreleased' : false
    },
    {
        'id' : 148,
        'title' : 'Gonna Do It Soon',
        'alternative_titles' : '',
        'permalink' : '/lyrics/gonna-do-it-soon/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley',
        'original_performer' : 'Nightnurse',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Wild Surf - Single',
        'appears_on_slug' : 'singles/wild-surf',
        'first_released' : 'November 23, 1998',
        'first_released_raw' : '1998-11-23',
        'unreleased' : false
    },
    {
        'id' : 149,
        'title' : 'Goodbye Tractor',
        'alternative_titles' : '',
        'permalink' : '/lyrics/goodbye-tractor/',
        'songwriter' : 'Music: Mark Hamilton',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Shed - Demo tape',
        'appears_on_slug' : 'demos/shed',
        'first_released' : 'September 1, 1992',
        'first_released_raw' : '1992-09-01',
        'unreleased' : false
    },
    {
        'id' : 150,
        'title' : 'Goog + Gegg',
        'alternative_titles' : '',
        'permalink' : '/lyrics/goog-gegg/',
        'songwriter' : 'Lyrics & Music: Ash',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Solar Happy - Demo',
        'appears_on_slug' : 'demos/solar-happy',
        'first_released' : 'June 29, 1992',
        'first_released_raw' : '1992-06-29',
        'unreleased' : false
    },
    {
        'id' : 151,
        'title' : 'Gouge Away',
        'alternative_titles' : '',
        'permalink' : '/lyrics/gouge-away/',
        'songwriter' : 'Lyrics & Music: Black Francis',
        'original_performer' : 'Pixies',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 152,
        'title' : 'Goy’s Scars',
        'alternative_titles' : '',
        'permalink' : '/lyrics/goys-scars/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 153,
        'title' : 'Grey Will Fade',
        'alternative_titles' : '',
        'permalink' : '/lyrics/grey-will-fade/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'There’s a Star - Single',
        'appears_on_slug' : 'singles/theres-a-star',
        'first_released' : 'December 31, 2001',
        'first_released_raw' : '2001-12-31',
        'unreleased' : false
    },
    {
        'id' : 154,
        'title' : 'Halloween',
        'alternative_titles' : '',
        'permalink' : '/lyrics/halloween/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'A Life Less Ordinary - Single',
        'appears_on_slug' : 'singles/a-life-less-ordinary',
        'first_released' : 'October 27, 1997',
        'first_released_raw' : '1997-10-27',
        'unreleased' : false
    },
    {
        'id' : 155,
        'title' : 'Have Yourself a Merry Little Christmas',
        'alternative_titles' : '',
        'permalink' : '/lyrics/have-yourself-a-merry-little-christmas/',
        'songwriter' : 'Lyrics: Ralph Blane. Music: Hugh Martin',
        'original_performer' : 'Judy Garland',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 156,
        'title' : 'Heart Attack',
        'alternative_titles' : '',
        'permalink' : '/lyrics/heart-attack/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler & Mark Hamilton',
        'original_performer' : '',
        'project_name' : 'Vietnam',
        'song_type' : 'Original',
        'appears_on' : 'Vietnam - Demo tape',
        'appears_on_slug' : 'demos/vietnam',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 157,
        'title' : 'Hedonism',
        'alternative_titles' : '',
        'permalink' : '/lyrics/hedonism/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Kablammo!',
        'appears_on_slug' : 'albums/kablammo',
        'first_released' : 'May 25, 2015',
        'first_released_raw' : '2015-05-25',
        'unreleased' : false
    },
    {
        'id' : 158,
        'title' : 'Hello, Goodbye',
        'alternative_titles' : '',
        'permalink' : '/lyrics/hello-goodbye/',
        'songwriter' : 'Lyrics & Music: John Lennon and Paul McCartney',
        'original_performer' : 'The Beatles',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Little Infinity - EP',
        'appears_on_slug' : 'eps/little-infinity',
        'first_released' : 'June 16, 2012',
        'first_released_raw' : '2012-06-16',
        'unreleased' : false
    },
    {
        'id' : 159,
        'title' : 'Here',
        'alternative_titles' : '',
        'permalink' : '/lyrics/here/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Shed - Demo tape',
        'appears_on_slug' : 'demos/shed',
        'first_released' : 'September 1, 1992',
        'first_released_raw' : '1992-09-01',
        'unreleased' : false
    },
    {
        'id' : 160,
        'title' : 'Here Comes the Music',
        'alternative_titles' : '',
        'permalink' : '/lyrics/here-comes-the-music/',
        'songwriter' : 'Lyrics & Music: Mark Cullen',
        'original_performer' : 'Fixed Stars',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'There’s a Star - Single',
        'appears_on_slug' : 'singles/theres-a-star',
        'first_released' : 'December 31, 2001',
        'first_released_raw' : '2001-12-31',
        'unreleased' : false
    },
    {
        'id' : 161,
        'title' : 'Here Comes the Summer',
        'alternative_titles' : '',
        'permalink' : '/lyrics/here-comes-the-summer/',
        'songwriter' : 'Lyrics & Music: John O’Neill',
        'original_performer' : 'The Undertones',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 162,
        'title' : 'Here Comes Your Man',
        'alternative_titles' : '',
        'permalink' : '/lyrics/here-comes-your-man/',
        'songwriter' : 'Lyrics & Music: Black Francis',
        'original_performer' : 'Pixies',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 163,
        'title' : 'Heroin, Vodka, White Noise',
        'alternative_titles' : 'Portishead',
        'permalink' : '/lyrics/heroin-vodka-white-noise/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler & Mark Hamilton',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Jesus Says - Single',
        'appears_on_slug' : 'singles/jesus-says',
        'first_released' : 'September 21, 1998',
        'first_released_raw' : '1998-09-21',
        'unreleased' : false
    },
    {
        'id' : 164,
        'title' : 'Higgs Boson',
        'alternative_titles' : '',
        'permalink' : '/lyrics/higgs-boson/',
        'songwriter' : 'Lyrics & Music: Ash',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 165,
        'title' : 'Hold',
        'alternative_titles' : '',
        'permalink' : '/lyrics/hold/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : 'Tim Wheeler',
        'song_type' : 'Original',
        'appears_on' : 'Lost Domain',
        'appears_on_slug' : 'solo/lost-domain',
        'first_released' : 'November 10, 2014',
        'first_released_raw' : '2014-11-10',
        'unreleased' : false
    },
    {
        'id' : 166,
        'title' : 'Home for the Holidays',
        'alternative_titles' : '',
        'permalink' : '/lyrics/home-for-the-holidays/',
        'songwriter' : 'Lyrics & Music: Emma-Lee Moss & Tim Wheeler',
        'original_performer' : '',
        'project_name' : 'Emmy the Great & Tim Wheeler',
        'song_type' : 'Original',
        'appears_on' : 'This Is Christmas',
        'appears_on_slug' : 'solo/this-is-christmas',
        'first_released' : 'November 21, 2011',
        'first_released_raw' : '2011-11-21',
        'unreleased' : false
    },
    {
        'id' : 167,
        'title' : 'Hook You Up',
        'alternative_titles' : '',
        'permalink' : '/lyrics/hook-you-up/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'True Love',
        'appears_on_slug' : 'solo/true-love',
        'first_released' : 'May 16, 2017',
        'first_released_raw' : '2017-05-16',
        'unreleased' : false
    },
    {
        'id' : 168,
        'title' : 'Hospital',
        'alternative_titles' : '',
        'permalink' : '/lyrics/hospital/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : 'Tim Wheeler',
        'song_type' : 'Original',
        'appears_on' : 'Lost Domain',
        'appears_on_slug' : 'solo/lost-domain',
        'first_released' : 'November 10, 2014',
        'first_released_raw' : '2014-11-10',
        'unreleased' : false
    },
    {
        'id' : 169,
        'title' : 'Hours',
        'alternative_titles' : '',
        'permalink' : '/lyrics/hours/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'True Love',
        'appears_on_slug' : 'solo/true-love',
        'first_released' : 'May 16, 2017',
        'first_released_raw' : '2017-05-16',
        'unreleased' : false
    },
    {
        'id' : 170,
        'title' : 'Hulk Hogan Bubblebath',
        'alternative_titles' : '',
        'permalink' : '/lyrics/hulk-hogan-bubblebath/',
        'songwriter' : 'Lyrics: Norman Mailer. Music: Mark Hamilton, Tim Wheeler & Rick McMurray',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Uncle Pat - Single',
        'appears_on_slug' : 'singles/uncle-pat',
        'first_released' : 'October 17, 1994',
        'first_released_raw' : '1994-10-17',
        'unreleased' : false
    },
    {
        'id' : 171,
        'title' : 'I Bleed',
        'alternative_titles' : '',
        'permalink' : '/lyrics/i-bleed/',
        'songwriter' : 'Lyrics & Music: Black Francis',
        'original_performer' : 'Pixies',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 172,
        'title' : 'I Can Only Dream',
        'alternative_titles' : '',
        'permalink' : '/lyrics/i-can-only-dream/',
        'songwriter' : 'Lyrics & Music: John O’Neill',
        'original_performer' : 'The Undertones',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 173,
        'title' : 'I Could Be So Good for You',
        'alternative_titles' : '',
        'permalink' : '/lyrics/i-could-be-so-good-for-you/',
        'songwriter' : 'Lyrics & Music: Dennis Waterman and Gerard Kenny',
        'original_performer' : 'Dennis Waterman',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 174,
        'title' : 'I Cut My Own Hair',
        'alternative_titles' : '',
        'permalink' : '/lyrics/i-cut-my-own-hair/',
        'songwriter' : 'Lyrics & Music: We Are Scientists',
        'original_performer' : 'We Are Scientists',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 175,
        'title' : 'I Don’t Mind',
        'alternative_titles' : '',
        'permalink' : '/lyrics/i-dont-mind/',
        'songwriter' : 'Lyrics & Music: Pete Shelley',
        'original_performer' : 'The Buzzcocks',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Envy - Single',
        'appears_on_slug' : 'singles/envy',
        'first_released' : 'August 26, 2002',
        'first_released_raw' : '2002-08-26',
        'unreleased' : false
    },
    {
        'id' : 176,
        'title' : 'I Need Somebody',
        'alternative_titles' : '',
        'permalink' : '/lyrics/i-need-somebody/',
        'songwriter' : 'Lyrics & Music: Mark Hamilton',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Goldfinger - Single',
        'appears_on_slug' : 'singles/goldfinger',
        'first_released' : 'April 15, 1996',
        'first_released_raw' : '1996-04-15',
        'unreleased' : false
    },
    {
        'id' : 177,
        'title' : 'I Only Want to Be With You',
        'alternative_titles' : '',
        'permalink' : '/lyrics/i-only-want-to-be-with-you/',
        'songwriter' : 'Lyrics & Music: Mike Hawker & Ivor Raymonde',
        'original_performer' : 'Dusty Springfield',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Barbie - Single',
        'appears_on_slug' : 'singles/barbie',
        'first_released' : 'March 3, 1997',
        'first_released_raw' : '1997-03-03',
        'unreleased' : false
    },
    {
        'id' : 178,
        'title' : 'I Shall Not Die',
        'alternative_titles' : '',
        'permalink' : '/lyrics/i-shall-not-die/',
        'songwriter' : 'Music: Tim Wheeler. Lyrics: Translated from Gaelic by Frank O’Connor',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Envy - Single',
        'appears_on_slug' : 'singles/envy',
        'first_released' : 'August 26, 2002',
        'first_released_raw' : '2002-08-26',
        'unreleased' : false
    },
    {
        'id' : 179,
        'title' : 'I Started a Fire',
        'alternative_titles' : '',
        'permalink' : '/lyrics/i-started-a-fire/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Twilight of the Innocents',
        'appears_on_slug' : 'albums/twilight-of-the-innocents',
        'first_released' : 'February 19, 2007',
        'first_released_raw' : '2007-02-19',
        'unreleased' : false
    },
    {
        'id' : 180,
        'title' : 'I Wanna Be Your Boyfriend',
        'alternative_titles' : '',
        'permalink' : '/lyrics/i-wanna-be-your-boyfriend/',
        'songwriter' : 'Lyrics & Music: Tommy Ramone',
        'original_performer' : 'Ramones',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 181,
        'title' : 'I Want You to Know',
        'alternative_titles' : '',
        'permalink' : '/lyrics/i-want-you-to-know/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'The Deep Blue',
        'appears_on_slug' : 'solo/the-deep-blue',
        'first_released' : 'March 5, 2007',
        'first_released_raw' : '2007-03-05',
        'unreleased' : false
    },
    {
        'id' : 182,
        'title' : 'I Wish It Could Be Christmas Everyday',
        'alternative_titles' : '',
        'permalink' : '/lyrics/i-wish-it-could-be-christmas-everyday/',
        'songwriter' : 'Lyrics & Music: Roy Wood',
        'original_performer' : 'Wizzard',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'War Child Compilation',
        'appears_on_slug' : '',
        'first_released' : 'December 1, 2004',
        'first_released_raw' : '2004-12-01',
        'unreleased' : false
    },
    {
        'id' : 183,
        'title' : 'I Wish It Was Christmas Today',
        'alternative_titles' : '',
        'permalink' : '/lyrics/i-wish-it-was-christmas-today/',
        'songwriter' : 'Lyrics & Music: Julian Casablancas',
        'original_performer' : 'Julian Casablancas',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 184,
        'title' : 'I’d Give You Anything',
        'alternative_titles' : '',
        'permalink' : '/lyrics/id-give-you-anything/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : '1977',
        'appears_on_slug' : 'albums/1977',
        'first_released' : 'May 6, 1996',
        'first_released_raw' : '1996-05-06',
        'unreleased' : false
    },
    {
        'id' : 185,
        'title' : 'I’m Gonna Fall',
        'alternative_titles' : '',
        'permalink' : '/lyrics/im-gonna-fall/',
        'songwriter' : 'Lyrics: Tim Wheeler. Music: Tim Wheeler & Mark Hamilton',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Nu-Clear Sounds',
        'appears_on_slug' : 'albums/nu-clear-sounds',
        'first_released' : 'October 5, 1998',
        'first_released_raw' : '1998-10-05',
        'unreleased' : false
    },
    {
        'id' : 186,
        'title' : 'I’m on Drugs',
        'alternative_titles' : '',
        'permalink' : '/lyrics/im-on-drugs/',
        'songwriter' : 'Lyrics & Music: Serious Drinking',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'I’m on Drugs - Single',
        'appears_on_slug' : 'singles/im-on-drugs',
        'first_released' : 'February 15, 2003',
        'first_released_raw' : '2003-02-15',
        'unreleased' : false
    },
    {
        'id' : 187,
        'title' : 'I’ve Been Tired',
        'alternative_titles' : '',
        'permalink' : '/lyrics/ive-been-tired/',
        'songwriter' : 'Lyrics & Music: Black Francis',
        'original_performer' : 'Pixies',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 188,
        'title' : 'Ichiban',
        'alternative_titles' : 'Survivor',
        'permalink' : '/lyrics/ichiban/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Ichiban - Single',
        'appears_on_slug' : 'a-z-singles-series/ichiban',
        'first_released' : 'January 4, 2010',
        'first_released_raw' : '2010-01-04',
        'unreleased' : false
    },
    {
        'id' : 189,
        'title' : 'Incoming Waves',
        'alternative_titles' : '',
        'permalink' : '/lyrics/incoming-waves/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Islands',
        'appears_on_slug' : 'albums/islands',
        'first_released' : 'May 18, 2018',
        'first_released_raw' : '2018-05-18',
        'unreleased' : false
    },
    {
        'id' : 190,
        'title' : 'Innocent Smile',
        'alternative_titles' : '',
        'permalink' : '/lyrics/innocent-smile/',
        'songwriter' : 'Lyrics & Music: Mark Hamilton',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : '1977',
        'appears_on_slug' : 'albums/1977',
        'first_released' : 'May 6, 1996',
        'first_released_raw' : '1996-05-06',
        'unreleased' : false
    },
    {
        'id' : 191,
        'title' : 'Insects',
        'alternative_titles' : '',
        'permalink' : '/lyrics/insects/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Insects - Single',
        'appears_on_slug' : 'a-z-singles-series/insects',
        'first_released' : 'May 10, 2010',
        'first_released_raw' : '2010-05-10',
        'unreleased' : false
    },
    {
        'id' : 192,
        'title' : 'Instinct',
        'alternative_titles' : '',
        'permalink' : '/lyrics/instinct/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Instinct - Single',
        'appears_on_slug' : 'a-z-singles-series/instinct',
        'first_released' : 'July 5, 2010',
        'first_released_raw' : '2010-07-05',
        'unreleased' : false
    },
    {
        'id' : 193,
        'title' : 'Intense Thing',
        'alternative_titles' : '',
        'permalink' : '/lyrics/intense-thing/',
        'songwriter' : 'Lyrics: Tim Wheeler. Music: Tim Wheeler, Mark Hamilton & Rick McMurray',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Trailer',
        'appears_on_slug' : 'eps/trailer',
        'first_released' : 'October 17, 1994',
        'first_released_raw' : '1994-10-17',
        'unreleased' : false
    },
    {
        'id' : 194,
        'title' : 'Interstate Love Song',
        'alternative_titles' : '',
        'permalink' : '/lyrics/interstate-love-song/',
        'songwriter' : 'Lyrics: Scott Weiland. Music: Robert DeLeo',
        'original_performer' : 'Stone Temple Pilots',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 195,
        'title' : 'Into the White',
        'alternative_titles' : '',
        'permalink' : '/lyrics/into-the-white/',
        'songwriter' : 'Lyrics & Music: Black Francis',
        'original_performer' : 'Pixies',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 196,
        'title' : 'Iris',
        'alternative_titles' : '',
        'permalink' : '/lyrics/iris/',
        'songwriter' : 'Lyrics & Music: Emma-Lee Moss',
        'original_performer' : 'Emmy the Great',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 197,
        'title' : 'Is It True?',
        'alternative_titles' : '',
        'permalink' : '/lyrics/is-it-true/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Islands',
        'appears_on_slug' : 'albums/islands',
        'first_released' : 'May 18, 2018',
        'first_released_raw' : '2018-05-18',
        'unreleased' : false
    },
    {
        'id' : 198,
        'title' : 'Isla de Encanta',
        'alternative_titles' : '',
        'permalink' : '/lyrics/isla-de-encanta/',
        'songwriter' : 'Lyrics & Music: Black Francis',
        'original_performer' : 'Pixies',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 199,
        'title' : 'Island in the Sun',
        'alternative_titles' : '',
        'permalink' : '/lyrics/island-in-the-sun/',
        'songwriter' : 'Lyrics & Music: Rivers Cuomo',
        'original_performer' : 'Weezer',
        'project_name' : 'Emmy the Great & Tim Wheeler',
        'song_type' : 'Cover',
        'appears_on' : 'This Is Christmas',
        'appears_on_slug' : 'solo/this-is-christmas',
        'first_released' : 'November 23, 2011',
        'first_released_raw' : '2011-11-23',
        'unreleased' : false
    },
    {
        'id' : 200,
        'title' : 'It Isn’t Over',
        'alternative_titles' : '',
        'permalink' : '/lyrics/it-isnt-over/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'The Deep Blue',
        'appears_on_slug' : 'solo/the-deep-blue',
        'first_released' : 'March 5, 2007',
        'first_released_raw' : '2007-03-05',
        'unreleased' : false
    },
    {
        'id' : 201,
        'title' : 'It’s a Trap',
        'alternative_titles' : '',
        'permalink' : '/lyrics/its-a-trap/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Islands',
        'appears_on_slug' : 'albums/islands',
        'first_released' : 'May 18, 2018',
        'first_released_raw' : '2018-05-18',
        'unreleased' : false
    },
    {
        'id' : 202,
        'title' : 'Jack & Ginger',
        'alternative_titles' : '',
        'permalink' : '/lyrics/jack-and-ginger/',
        'songwriter' : 'Lyrics & Music: We Are Scientists',
        'original_performer' : 'We Are Scientists',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 203,
        'title' : 'Jack Names the Planets',
        'alternative_titles' : '',
        'permalink' : '/lyrics/jack-names-the-planets/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Trailer',
        'appears_on_slug' : 'eps/trailer',
        'first_released' : 'February 7, 1994',
        'first_released_raw' : '1994-02-07',
        'unreleased' : false
    },
    {
        'id' : 204,
        'title' : 'Jackie Wilson Said (I’m in Heaven When You Smile)',
        'alternative_titles' : '',
        'permalink' : '/lyrics/jackie-wilson-said-im-in-heaven-when-you-smile/',
        'songwriter' : 'Lyrics & Music: Van Morrison',
        'original_performer' : 'Van Morrison',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 205,
        'title' : 'Jailhouse Rock',
        'alternative_titles' : '',
        'permalink' : '/lyrics/jailhouse-rock/',
        'songwriter' : 'Lyrics & Music: Jerry Leiber & Mike Stoller',
        'original_performer' : 'Elvis Presley',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 206,
        'title' : 'Jazz ’59',
        'alternative_titles' : '',
        'permalink' : '/lyrics/jazz-59/',
        'songwriter' : 'Music: Mark Hamilton',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'BBC Sessions 1994-1999',
        'appears_on_slug' : 'compilations/bbc-sessions-1994-1999',
        'first_released' : 'October 17, 1994',
        'first_released_raw' : '1994-10-17',
        'unreleased' : false
    },
    {
        'id' : 207,
        'title' : 'Jesus Says',
        'alternative_titles' : 'V U, Ditto, Velvets',
        'permalink' : '/lyrics/jesus-says/',
        'songwriter' : 'Lyrics: Tim Wheeler. Music: Tim Wheeler & Mark Hamilton',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Nu-Clear Sounds',
        'appears_on_slug' : 'albums/nu-clear-sounds',
        'first_released' : 'September 21, 1998',
        'first_released_raw' : '1998-09-21',
        'unreleased' : false
    },
    {
        'id' : 208,
        'title' : 'Jesus the Reindeer',
        'alternative_titles' : '',
        'permalink' : '/lyrics/jesus-the-reindeer/',
        'songwriter' : 'Lyrics & Music: Emma-Lee Moss & Tim Wheeler',
        'original_performer' : '',
        'project_name' : 'Emmy the Great & Tim Wheeler',
        'song_type' : 'Original',
        'appears_on' : 'This Is Christmas',
        'appears_on_slug' : 'solo/this-is-christmas',
        'first_released' : 'November 21, 2011',
        'first_released_raw' : '2011-11-21',
        'unreleased' : false
    },
    {
        'id' : 209,
        'title' : 'Jimmy Jimmy',
        'alternative_titles' : '',
        'permalink' : '/lyrics/jimmy-jimmy/',
        'songwriter' : 'Lyrics & Music: John O’Neill',
        'original_performer' : 'The Undertones',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 210,
        'title' : 'Joy Kicks Darkness',
        'alternative_titles' : '',
        'permalink' : '/lyrics/joy-kicks-darkness/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Joy Kicks Darkness - Single',
        'appears_on_slug' : 'a-z-singles-series/joy-kicks-darkness',
        'first_released' : 'October 26, 2009',
        'first_released_raw' : '2009-10-26',
        'unreleased' : false
    },
    {
        'id' : 211,
        'title' : 'Jump in the Line',
        'alternative_titles' : '',
        'permalink' : '/lyrics/jump-in-the-line/',
        'songwriter' : 'Lyrics & Music: Lord Kitchener',
        'original_performer' : 'Harry Belafonte',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Jump in the Line - Single',
        'appears_on_slug' : 'singles/jump-in-the-line',
        'first_released' : 'April 2, 2025',
        'first_released_raw' : '2025-04-02',
        'unreleased' : false
    },
    {
        'id' : 212,
        'title' : 'Just Like Heaven',
        'alternative_titles' : '',
        'permalink' : '/lyrics/just-like-heaven/',
        'songwriter' : 'Lyrics & Music: Pearl Thompson, Boris Williams, Lol Tolhurst, Simon Gallup & Robert Smith',
        'original_performer' : 'The Cure',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 213,
        'title' : 'Juvenilia',
        'alternative_titles' : '',
        'permalink' : '/lyrics/juvenilia/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Kablammo!',
        'appears_on_slug' : 'albums/kablammo',
        'first_released' : 'May 25, 2015',
        'first_released_raw' : '2015-05-25',
        'unreleased' : false
    },
    {
        'id' : 214,
        'title' : 'Kamakura',
        'alternative_titles' : '',
        'permalink' : '/lyrics/kamakura/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'A-Z Vol.1',
        'appears_on_slug' : 'compilations/a-z-vol-1',
        'first_released' : 'April 7, 2010',
        'first_released_raw' : '2010-04-07',
        'unreleased' : false
    },
    {
        'id' : 215,
        'title' : 'Keep on Moving On',
        'alternative_titles' : '',
        'permalink' : '/lyrics/keep-on-moving-on/',
        'songwriter' : 'Lyrics & Music: Andy Burrows',
        'original_performer' : 'Andy Burrows',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 216,
        'title' : 'Kill Kill Daffodil',
        'alternative_titles' : '',
        'permalink' : '/lyrics/kill-kill-daffodil/',
        'songwriter' : 'Lyrics & Music: Lord Hagos',
        'original_performer' : '',
        'project_name' : 'Donkey Sanctuary',
        'song_type' : 'Original',
        'appears_on' : 'Kill Kill Daffodil - Single',
        'appears_on_slug' : 'solo/kill-kill-daffodil',
        'first_released' : 'April 27, 2020',
        'first_released_raw' : '2020-04-27',
        'unreleased' : false
    },
    {
        'id' : 217,
        'title' : 'Kill Your Friends',
        'alternative_titles' : '',
        'permalink' : '/lyrics/kill-your-friends/',
        'songwriter' : 'Lyrics & Music: Ash',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 218,
        'title' : 'Kim Wilde',
        'alternative_titles' : '',
        'permalink' : '/lyrics/kim-wilde/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'Grey Will Fade',
        'appears_on_slug' : 'solo/grey-will-fade',
        'first_released' : 'August 16, 2004',
        'first_released_raw' : '2004-08-16',
        'unreleased' : false
    },
    {
        'id' : 219,
        'title' : 'Kingdom of Shadow',
        'alternative_titles' : 'A Little High',
        'permalink' : '/lyrics/kingdom-of-shadow/',
        'songwriter' : 'Lyrics & Music: Rick McMurray',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Polaris - Single',
        'appears_on_slug' : 'singles/polaris',
        'first_released' : 'June 18, 2007',
        'first_released_raw' : '2007-06-18',
        'unreleased' : false
    },
    {
        'id' : 220,
        'title' : 'Kung Fu',
        'alternative_titles' : '',
        'permalink' : '/lyrics/kung-fu/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : '1977',
        'appears_on_slug' : 'albums/1977',
        'first_released' : 'March 20, 1995',
        'first_released_raw' : '1995-03-20',
        'unreleased' : false
    },
    {
        'id' : 221,
        'title' : 'L.A Stormy',
        'alternative_titles' : '',
        'permalink' : '/lyrics/la-stormy/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley, Charlie Alex March & Michael Lovett',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'True Love',
        'appears_on_slug' : 'solo/true-love',
        'first_released' : 'May 16, 2017',
        'first_released_raw' : '2017-05-16',
        'unreleased' : false
    },
    {
        'id' : 222,
        'title' : 'La La La',
        'alternative_titles' : '',
        'permalink' : '/lyrics/la-la-la/',
        'songwriter' : 'Lyrics: Tim Wheeler. Music: Tim Wheeler, Mark Hamilton & Rick McMurray',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Garage Girl - Demo tape',
        'appears_on_slug' : 'demos/garage-girl',
        'first_released' : 'February 1, 1993',
        'first_released_raw' : '1993-02-01',
        'unreleased' : false
    },
    {
        'id' : 223,
        'title' : 'La La Love You',
        'alternative_titles' : '',
        'permalink' : '/lyrics/la-la-love-you/',
        'songwriter' : 'Lyrics & Music: Black Francis',
        'original_performer' : 'Pixies',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 224,
        'title' : 'Last Christmas',
        'alternative_titles' : '',
        'permalink' : '/lyrics/last-christmas/',
        'songwriter' : 'Lyrics & Music: George Michael',
        'original_performer' : 'Wham!',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 225,
        'title' : 'Lay All Your Love on Me',
        'alternative_titles' : '',
        'permalink' : '/lyrics/lay-all-your-love-on-me/',
        'songwriter' : 'Lyrics & Music: Benny Anderson and Bjorn Ulvaeus',
        'original_performer' : 'ABBA',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Little Infinity - EP',
        'appears_on_slug' : 'eps/little-infinity',
        'first_released' : 'June 16, 2012',
        'first_released_raw' : '2012-06-16',
        'unreleased' : false
    },
    {
        'id' : 226,
        'title' : 'Lay Down Your Arms',
        'alternative_titles' : '',
        'permalink' : '/lyrics/lay-down-your-arms/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Lay Down Your Arms - Single',
        'appears_on_slug' : 'a-z-singles-series/lay-down-your-arms',
        'first_released' : 'December 22, 2009',
        'first_released_raw' : '2009-12-22',
        'unreleased' : false
    },
    {
        'id' : 227,
        'title' : 'Let It Flow',
        'alternative_titles' : '',
        'permalink' : '/lyrics/let-it-flow/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : '1977',
        'appears_on_slug' : 'albums/1977',
        'first_released' : 'May 6, 1996',
        'first_released_raw' : '1996-05-06',
        'unreleased' : false
    },
    {
        'id' : 228,
        'title' : 'Let’s Ride',
        'alternative_titles' : '',
        'permalink' : '/lyrics/lets-ride/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Kablammo!',
        'appears_on_slug' : 'albums/kablammo',
        'first_released' : 'May 25, 2015',
        'first_released_raw' : '2015-05-25',
        'unreleased' : false
    },
    {
        'id' : 229,
        'title' : 'Lights Out',
        'alternative_titles' : '',
        'permalink' : '/lyrics/lights-out/',
        'songwriter' : 'Lyrics & Music: Rick McMurray',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Lights Out - Single',
        'appears_on_slug' : 'a-z-singles-series/lights-out',
        'first_released' : 'July 6, 2010',
        'first_released_raw' : '2010-07-06',
        'unreleased' : false
    },
    {
        'id' : 230,
        'title' : 'Like a God',
        'alternative_titles' : '',
        'permalink' : '/lyrics/like-a-god/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Race the Night',
        'appears_on_slug' : 'albums/race-the-night',
        'first_released' : 'July 19, 2023',
        'first_released_raw' : '2023-07-19',
        'unreleased' : false
    },
    {
        'id' : 231,
        'title' : 'Like a God (Reprise)',
        'alternative_titles' : '',
        'permalink' : '/lyrics/like-a-god-reprise/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Race the Night',
        'appears_on_slug' : 'albums/race-the-night',
        'first_released' : 'September 15, 2023',
        'first_released_raw' : '2023-09-15',
        'unreleased' : false
    },
    {
        'id' : 232,
        'title' : 'Little Duck',
        'alternative_titles' : '',
        'permalink' : '/lyrics/little-duck/',
        'songwriter' : 'Lyrics: Tim Wheeler. Music: Mark Hamilton',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Shed - Demo tape',
        'appears_on_slug' : 'demos/shed',
        'first_released' : 'September 1, 1992',
        'first_released_raw' : '1992-09-01',
        'unreleased' : false
    },
    {
        'id' : 233,
        'title' : 'Little Sahara',
        'alternative_titles' : '',
        'permalink' : '/lyrics/little-sahara/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'New Worlds',
        'appears_on_slug' : 'solo/new-worlds',
        'first_released' : 'October 16, 2009',
        'first_released_raw' : '2009-10-16',
        'unreleased' : false
    },
    {
        'id' : 234,
        'title' : 'Locked-In',
        'alternative_titles' : '',
        'permalink' : '/lyrics/locked-in/',
        'songwriter' : 'Music: Conor Creaney, David Kitt and Tim Wheeler',
        'original_performer' : '',
        'project_name' : 'The Lock-in',
        'song_type' : 'Original',
        'appears_on' : 'The Lock-in',
        'appears_on_slug' : 'solo/the-lock-in',
        'first_released' : 'June 21, 2019',
        'first_released_raw' : '2019-06-21',
        'unreleased' : false
    },
    {
        'id' : 235,
        'title' : 'Locked-Out',
        'alternative_titles' : '',
        'permalink' : '/lyrics/locked-out/',
        'songwriter' : 'Music: Conor Creaney, David Kitt and Tim Wheeler',
        'original_performer' : '',
        'project_name' : 'The Lock-in',
        'song_type' : 'Original',
        'appears_on' : 'The Lock-in',
        'appears_on_slug' : 'solo/the-lock-in',
        'first_released' : 'June 21, 2019',
        'first_released_raw' : '2019-06-21',
        'unreleased' : false
    },
    {
        'id' : 236,
        'title' : 'Lonely Waltz',
        'alternative_titles' : '',
        'permalink' : '/lyrics/lonely-waltz/',
        'songwriter' : 'Music: Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'True Love',
        'appears_on_slug' : 'solo/true-love',
        'first_released' : 'May 16, 2017',
        'first_released_raw' : '2017-05-16',
        'unreleased' : false
    },
    {
        'id' : 237,
        'title' : 'Lose Control',
        'alternative_titles' : '',
        'permalink' : '/lyrics/lose-control-2/',
        'songwriter' : 'Lyrics: Barry Peak. Music: Backwater',
        'original_performer' : 'Backwater',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Wild Surf - Single',
        'appears_on_slug' : 'singles/wild-surf',
        'first_released' : 'November 23, 1998',
        'first_released_raw' : '1998-11-23',
        'unreleased' : false
    },
    {
        'id' : 238,
        'title' : 'Lose Control',
        'alternative_titles' : '',
        'permalink' : '/lyrics/lose-control/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler & Mark Hamilton',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : '1977',
        'appears_on_slug' : 'albums/1977',
        'first_released' : 'May 6, 1996',
        'first_released_raw' : '1996-05-06',
        'unreleased' : false
    },
    {
        'id' : 239,
        'title' : 'Lost Domain',
        'alternative_titles' : '',
        'permalink' : '/lyrics/lost-domain/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : 'Tim Wheeler',
        'song_type' : 'Original',
        'appears_on' : 'Lost Domain',
        'appears_on_slug' : 'solo/lost-domain',
        'first_released' : 'November 10, 2014',
        'first_released_raw' : '2014-11-10',
        'unreleased' : false
    },
    {
        'id' : 240,
        'title' : 'Lost in You',
        'alternative_titles' : '',
        'permalink' : '/lyrics/lost-in-you/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : '1977',
        'appears_on_slug' : 'albums/1977',
        'first_released' : 'May 6, 1996',
        'first_released_raw' : '1996-05-06',
        'unreleased' : false
    },
    {
        'id' : 241,
        'title' : 'Love Is For Sops',
        'alternative_titles' : '',
        'permalink' : '/lyrics/love-is-for-sops/',
        'songwriter' : 'Lyrics & Music: The Outcasts',
        'original_performer' : 'The Outcasts',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 242,
        'title' : 'Love’s Young Dream',
        'alternative_titles' : '',
        'permalink' : '/lyrics/loves-young-dream/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'The Deep Blue',
        'appears_on_slug' : 'solo/the-deep-blue',
        'first_released' : 'March 5, 2007',
        'first_released_raw' : '2007-03-05',
        'unreleased' : false
    },
    {
        'id' : 243,
        'title' : 'Low Ebb',
        'alternative_titles' : 'Ebbing Away',
        'permalink' : '/lyrics/low-ebb/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Nu-Clear Sounds',
        'appears_on_slug' : 'albums/nu-clear-sounds',
        'first_released' : 'October 5, 1998',
        'first_released_raw' : '1998-10-05',
        'unreleased' : false
    },
    {
        'id' : 244,
        'title' : 'Lust for Life',
        'alternative_titles' : '',
        'permalink' : '/lyrics/lust-for-life/',
        'songwriter' : 'Lyrics & Music: David Bowie & Iggy Pop',
        'original_performer' : 'Iggy Pop',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 245,
        'title' : 'Luther Ingo’s Star Cruiser',
        'alternative_titles' : '',
        'permalink' : '/lyrics/luther-ingos-star-cruiser/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler & Mark Hamilton',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Kung Fu - Single',
        'appears_on_slug' : 'singles/kung-fu',
        'first_released' : 'March 20, 1995',
        'first_released_raw' : '1995-03-20',
        'unreleased' : false
    },
    {
        'id' : 246,
        'title' : 'Machinery',
        'alternative_titles' : '',
        'permalink' : '/lyrics/machinery/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Kablammo!',
        'appears_on_slug' : 'albums/kablammo',
        'first_released' : 'May 25, 2015',
        'first_released_raw' : '2015-05-25',
        'unreleased' : false
    },
    {
        'id' : 247,
        'title' : 'Mamma Mia',
        'alternative_titles' : '',
        'permalink' : '/lyrics/mamma-mia/',
        'songwriter' : 'Lyrics & Music: Björn Ulvaeus, Benny Andersson and Stig Anderson',
        'original_performer' : 'ABBA',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 248,
        'title' : 'Marshmallow World',
        'alternative_titles' : '',
        'permalink' : '/lyrics/marshmallow-world/',
        'songwriter' : 'Lyrics: Carl Sigman. Music: Peter DeRose',
        'original_performer' : 'Bing Crosby',
        'project_name' : 'Emmy the Great & Tim Wheeler',
        'song_type' : 'Cover',
        'appears_on' : 'This Is Christmas',
        'appears_on_slug' : 'solo/this-is-christmas',
        'first_released' : 'November 21, 2011',
        'first_released_raw' : '2011-11-21',
        'unreleased' : false
    },
    {
        'id' : 249,
        'title' : 'Medicine',
        'alternative_titles' : '',
        'permalink' : '/lyrics/medicine/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : 'Tim Wheeler',
        'song_type' : 'Original',
        'appears_on' : 'Lost Domain',
        'appears_on_slug' : 'solo/lost-domain',
        'first_released' : 'November 10, 2014',
        'first_released_raw' : '2014-11-10',
        'unreleased' : false
    },
    {
        'id' : 250,
        'title' : 'Melon Farmer',
        'alternative_titles' : '',
        'permalink' : '/lyrics/melon-farmer/',
        'songwriter' : 'Lyrics & Music: Peak & Lowe',
        'original_performer' : 'Lazer Gun Nun',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Sometimes - Single',
        'appears_on_slug' : 'singles/sometimes',
        'first_released' : 'July 9, 2001',
        'first_released_raw' : '2001-07-09',
        'unreleased' : false
    },
    {
        'id' : 251,
        'title' : 'Meltdown',
        'alternative_titles' : '',
        'permalink' : '/lyrics/meltdown/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler & Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Meltdown',
        'appears_on_slug' : 'albums/meltdown',
        'first_released' : 'May 17, 2004',
        'first_released_raw' : '2004-05-17',
        'unreleased' : false
    },
    {
        'id' : 252,
        'title' : 'Merry Christmas (I Don’t Want to Fight Tonight)',
        'alternative_titles' : '',
        'permalink' : '/lyrics/merry-christmas-i-dont-want-fo-fight-tonight/',
        'songwriter' : 'Lyrics & Music: Joey Ramone',
        'original_performer' : 'Ramones',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 253,
        'title' : 'Message from Oscar Wilde and Patrick the Brewer',
        'alternative_titles' : '',
        'permalink' : '/lyrics/message-from-oscar-wilde-and-patrick-the-brewer/',
        'songwriter' : 'Lyrics: Oscar Wilde and Patrick the Brewer',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Petrol - Single',
        'appears_on_slug' : 'singles/petrol',
        'first_released' : 'August 15, 1994',
        'first_released_raw' : '1994-08-15',
        'unreleased' : false
    },
    {
        'id' : 254,
        'title' : 'Midnight in the Garden',
        'alternative_titles' : '',
        'permalink' : '/lyrics/midnight-in-the-garden/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'A-Z Vol.2',
        'appears_on_slug' : 'compilations/a-z-vol-2',
        'first_released' : 'October 11, 2010',
        'first_released_raw' : '2010-10-11',
        'unreleased' : false
    },
    {
        'id' : 255,
        'title' : 'Midnight Militia',
        'alternative_titles' : '',
        'permalink' : '/lyrics/midnight-militia/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Midnight Militia - Single',
        'appears_on_slug' : 'a-z-singles-series/midnight-militia',
        'first_released' : 'July 28, 2010',
        'first_released_raw' : '2010-07-28',
        'unreleased' : false
    },
    {
        'id' : 256,
        'title' : 'Mind Control',
        'alternative_titles' : '',
        'permalink' : '/lyrics/mind-control/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Mind Control - Single',
        'appears_on_slug' : 'a-z-singles-series/mind-control',
        'first_released' : 'April 26, 2010',
        'first_released_raw' : '2010-04-26',
        'unreleased' : false
    },
    {
        'id' : 257,
        'title' : 'Mini',
        'alternative_titles' : '',
        'permalink' : '/lyrics/mini/',
        'songwriter' : 'Lyrics & Music: Ash',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 258,
        'title' : 'Modern Girl',
        'alternative_titles' : '',
        'permalink' : '/lyrics/modern-girl/',
        'songwriter' : 'Lyrics & Music: Janet Weiss, Corin Tucker & Carrie Brownstein',
        'original_performer' : 'Sleater-Kinney',
        'project_name' : 'Emmy the Great & Tim Wheeler',
        'song_type' : 'Cover',
        'appears_on' : 'This Is Christmas',
        'appears_on_slug' : 'solo/this-is-christmas',
        'first_released' : 'November 23, 2011',
        'first_released_raw' : '2011-11-23',
        'unreleased' : false
    },
    {
        'id' : 259,
        'title' : 'Monkey Gone to Heaven',
        'alternative_titles' : '',
        'permalink' : '/lyrics/monkey-gone-to-heaven/',
        'songwriter' : 'Lyrics & Music: Black Francis',
        'original_performer' : 'Pixies',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 260,
        'title' : 'Monsoon',
        'alternative_titles' : '',
        'permalink' : '/lyrics/monsoon/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : 'Tim Wheeler',
        'song_type' : 'Original',
        'appears_on' : 'Lost Domain',
        'appears_on_slug' : 'solo/lost-domain',
        'first_released' : 'November 10, 2014',
        'first_released_raw' : '2014-11-10',
        'unreleased' : false
    },
    {
        'id' : 261,
        'title' : 'Moondust',
        'alternative_titles' : '',
        'permalink' : '/lyrics/moondust/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Kablammo!',
        'appears_on_slug' : 'albums/kablammo',
        'first_released' : 'May 25, 2015',
        'first_released_raw' : '2015-05-25',
        'unreleased' : false
    },
    {
        'id' : 262,
        'title' : 'Mountains of Mourne',
        'alternative_titles' : '',
        'permalink' : '/lyrics/mountains-of-mourne/',
        'songwriter' : 'Lyrics: Percy French',
        'original_performer' : 'Traditional Irish folk tune',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 263,
        'title' : 'Mrs. Robinson',
        'alternative_titles' : '',
        'permalink' : '/lyrics/mrs-robinson/',
        'songwriter' : 'Lyrics & Music: Paul Simon',
        'original_performer' : 'Simon & Garfunkel',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 264,
        'title' : 'My Perfect Cousin',
        'alternative_titles' : '',
        'permalink' : '/lyrics/my-perfect-cousin/',
        'songwriter' : 'Lyrics & Music: Damian O’Neill and Michael Bradley',
        'original_performer' : 'The Undertones',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 265,
        'title' : 'Neon',
        'alternative_titles' : '',
        'permalink' : '/lyrics/neon/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Neon - Single',
        'appears_on_slug' : 'a-z-singles-series/neon',
        'first_released' : 'February 1, 2010',
        'first_released_raw' : '2010-02-01',
        'unreleased' : false
    },
    {
        'id' : 266,
        'title' : 'New Tattoo',
        'alternative_titles' : '',
        'permalink' : '/lyrics/new-tattoo/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Twilight of the Innocents',
        'appears_on_slug' : 'albums/twilight-of-the-innocents',
        'first_released' : 'July 2, 2007',
        'first_released_raw' : '2007-07-02',
        'unreleased' : false
    },
    {
        'id' : 267,
        'title' : 'New Worlds',
        'alternative_titles' : '',
        'permalink' : '/lyrics/new-worlds/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'New Worlds',
        'appears_on_slug' : 'solo/new-worlds',
        'first_released' : 'October 16, 2009',
        'first_released_raw' : '2009-10-16',
        'unreleased' : false
    },
    {
        'id' : 268,
        'title' : 'New Year’s Day',
        'alternative_titles' : '',
        'permalink' : '/lyrics/new-years-day/',
        'songwriter' : 'Music: U2. Lyrics: Bono',
        'original_performer' : 'U2',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 269,
        'title' : 'Nicole',
        'alternative_titles' : '',
        'permalink' : '/lyrics/nicole/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler & Mark Hamilton',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Free All Angels',
        'appears_on_slug' : 'albums/free-all-angels',
        'first_released' : 'April 23, 2001',
        'first_released_raw' : '2001-04-23',
        'unreleased' : false
    },
    {
        'id' : 270,
        'title' : 'Night Terrors',
        'alternative_titles' : '',
        'permalink' : '/lyrics/night-terrors/',
        'songwriter' : 'Lyrics & Music: Mark Hamilton',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Night Terrors - Single',
        'appears_on_slug' : 'a-z-singles-series/night-terrors',
        'first_released' : 'March 2, 2010',
        'first_released_raw' : '2010-03-02',
        'unreleased' : false
    },
    {
        'id' : 271,
        'title' : 'Night Vision',
        'alternative_titles' : '',
        'permalink' : '/lyrics/night-vision/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley & Michael Lovett',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'True Love',
        'appears_on_slug' : 'solo/true-love',
        'first_released' : 'May 16, 2017',
        'first_released_raw' : '2017-05-16',
        'unreleased' : false
    },
    {
        'id' : 272,
        'title' : 'Nightfall',
        'alternative_titles' : '',
        'permalink' : '/lyrics/nightfall/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'A-Z Vol.2',
        'appears_on_slug' : 'compilations/a-z-vol-2',
        'first_released' : 'October 11, 2010',
        'first_released_raw' : '2010-10-11',
        'unreleased' : false
    },
    {
        'id' : 273,
        'title' : 'No Face (Jesus Christ)',
        'alternative_titles' : '',
        'permalink' : '/lyrics/no-face-jesus-christ/',
        'songwriter' : 'Lyrics & Music: Ash',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 274,
        'title' : 'No Heartbreaker',
        'alternative_titles' : '',
        'permalink' : '/lyrics/no-heartbreaker/',
        'songwriter' : 'Lyrics & Music: Rick McMurray',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'No Heartbreaker - Single',
        'appears_on_slug' : 'a-z-singles-series/no-heartbreaker',
        'first_released' : 'June 1, 2010',
        'first_released_raw' : '2010-06-01',
        'unreleased' : false
    },
    {
        'id' : 275,
        'title' : 'No More',
        'alternative_titles' : '',
        'permalink' : '/lyrics/no-more/',
        'songwriter' : 'Lyrics & Music: Ash',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Solar Happy - Demo',
        'appears_on_slug' : 'demos/solar-happy',
        'first_released' : 'June 29, 1992',
        'first_released_raw' : '1992-06-29',
        'unreleased' : false
    },
    {
        'id' : 276,
        'title' : 'No More Heroes',
        'alternative_titles' : '',
        'permalink' : '/lyrics/no-more-heroes/',
        'songwriter' : 'Lyrics & Music: Hugh Cornwell, Jean-Jacques Burnel and Jet Black',
        'original_performer' : 'The Stranglers',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 277,
        'title' : 'No Place to Hide',
        'alternative_titles' : '',
        'permalink' : '/lyrics/no-place-to-hide/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'There’s a Star - Single',
        'appears_on_slug' : 'singles/theres-a-star',
        'first_released' : 'December 31, 2001',
        'first_released_raw' : '2001-12-31',
        'unreleased' : false
    },
    {
        'id' : 278,
        'title' : 'Nocturne',
        'alternative_titles' : '',
        'permalink' : '/lyrics/nocturne/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler & Mark Hamilton',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Candy - Single',
        'appears_on_slug' : 'singles/candy',
        'first_released' : 'October 1, 2001',
        'first_released_raw' : '2001-10-01',
        'unreleased' : false
    },
    {
        'id' : 279,
        'title' : 'Nouvelles Aventures',
        'alternative_titles' : '',
        'permalink' : '/lyrics/nouvelles-aventures/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Nouvelles Aventures - Single',
        'appears_on_slug' : 'a-z-singles-series/nouvelles-aventures',
        'first_released' : 'September 22, 2010',
        'first_released_raw' : '2010-09-22',
        'unreleased' : false
    },
    {
        'id' : 280,
        'title' : 'Numbskull',
        'alternative_titles' : 'Knumbskull',
        'permalink' : '/lyrics/numbskull/',
        'songwriter' : 'Lyrics: Tim Wheeler. Music: Mark Hamilton',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Nu-Clear Sounds',
        'appears_on_slug' : 'albums/nu-clear-sounds',
        'first_released' : 'October 5, 1998',
        'first_released_raw' : '1998-10-05',
        'unreleased' : false
    },
    {
        'id' : 281,
        'title' : 'Obscure Thing',
        'alternative_titles' : '',
        'permalink' : '/lyrics/obscure-thing/',
        'songwriter' : 'Music: Mark Hamilton',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Trailer',
        'appears_on_slug' : 'eps/trailer',
        'first_released' : 'October 17, 1994',
        'first_released_raw' : '1994-10-17',
        'unreleased' : false
    },
    {
        'id' : 282,
        'title' : 'Obsolete',
        'alternative_titles' : '',
        'permalink' : '/lyrics/obsolete/',
        'songwriter' : 'Lyrics & Music: Ash',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 283,
        'title' : 'Oh Yeah',
        'alternative_titles' : '',
        'permalink' : '/lyrics/oh-yeah/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : '1977',
        'appears_on_slug' : 'albums/1977',
        'first_released' : 'May 6, 1996',
        'first_released_raw' : '1996-05-06',
        'unreleased' : false
    },
    {
        'id' : 284,
        'title' : 'Oh Yeah (Teenage Years)',
        'alternative_titles' : '',
        'permalink' : '/lyrics/oh-yeah-teenage-years/',
        'songwriter' : 'Lyrics & Music: Billy Lunn, Charlotte Cooper & Josh Morgan',
        'original_performer' : 'The Subways',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Oh Yeah (Squared) - Single',
        'appears_on_slug' : 'singles/oh-yeah-squared',
        'first_released' : 'September 26, 2023',
        'first_released_raw' : '2023-09-26',
        'unreleased' : false
    },
    {
        'id' : 285,
        'title' : 'Old Guy (Age Concern)',
        'alternative_titles' : '',
        'permalink' : '/lyrics/old-guy-age-concern/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler & Mark Hamilton',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Shed - Demo tape',
        'appears_on_slug' : 'demos/shed',
        'first_released' : 'September 1, 1992',
        'first_released_raw' : '1992-09-01',
        'unreleased' : false
    },
    {
        'id' : 286,
        'title' : 'On a Wave',
        'alternative_titles' : '',
        'permalink' : '/lyrics/on-a-wave/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Meltdown',
        'appears_on_slug' : 'albums/meltdown',
        'first_released' : 'May 17, 2004',
        'first_released_raw' : '2004-05-17',
        'unreleased' : false
    },
    {
        'id' : 287,
        'title' : 'On the Prowl',
        'alternative_titles' : '',
        'permalink' : '/lyrics/on-the-prowl/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler & Mark Hamilton',
        'original_performer' : '',
        'project_name' : 'Vietnam',
        'song_type' : 'Original',
        'appears_on' : 'Vietnam - Demo tape',
        'appears_on_slug' : 'demos/vietnam',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 288,
        'title' : 'One',
        'alternative_titles' : '',
        'permalink' : '/lyrics/one/',
        'songwriter' : 'Music: U2. Lyrics: Bono',
        'original_performer' : 'U2',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 289,
        'title' : 'One Last Song',
        'alternative_titles' : '',
        'permalink' : '/lyrics/one-last-song/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : 'Tim Wheeler',
        'song_type' : 'Original',
        'appears_on' : 'Sheltered Youth - EP',
        'appears_on_slug' : 'solo/sheltered-youth',
        'first_released' : 'November 10, 2014',
        'first_released_raw' : '2014-11-10',
        'unreleased' : false
    },
    {
        'id' : 290,
        'title' : 'One Person Playing Two Roles',
        'alternative_titles' : '',
        'permalink' : '/lyrics/one-person-playing-two-roles/',
        'songwriter' : 'Music: AMK. Lyrics: Wyman Wong',
        'original_performer' : 'Faye Wong',
        'project_name' : 'Emmy the Great & Tim Wheeler',
        'song_type' : 'Cover',
        'appears_on' : 'This Is Christmas',
        'appears_on_slug' : 'solo/this-is-christmas',
        'first_released' : 'November 23, 2011',
        'first_released_raw' : '2011-11-23',
        'unreleased' : false
    },
    {
        'id' : 291,
        'title' : 'Only Friend',
        'alternative_titles' : '',
        'permalink' : '/lyrics/only-friend/',
        'songwriter' : 'Lyrics: Tim Wheeler. Music: Tim Wheeler & Mark Hamilton',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Garage Girl - Demo tape',
        'appears_on_slug' : 'demos/garage-girl',
        'first_released' : 'February 1, 1993',
        'first_released_raw' : '1993-02-01',
        'unreleased' : false
    },
    {
        'id' : 292,
        'title' : 'Only in Dreams',
        'alternative_titles' : '',
        'permalink' : '/lyrics/only-in-dreams/',
        'songwriter' : 'Lyrics & Music: Rivers Cuomo',
        'original_performer' : 'Weezer',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Burn Baby Burn - Single',
        'appears_on_slug' : 'singles/burn-baby-burn',
        'first_released' : 'April 2, 2001',
        'first_released_raw' : '2001-04-02',
        'unreleased' : false
    },
    {
        'id' : 293,
        'title' : 'Orpheus',
        'alternative_titles' : 'Dirty Sanchez',
        'permalink' : '/lyrics/orpheus/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Meltdown',
        'appears_on_slug' : 'albums/meltdown',
        'first_released' : 'May 3, 2004',
        'first_released_raw' : '2004-05-03',
        'unreleased' : false
    },
    {
        'id' : 294,
        'title' : 'Oslo',
        'alternative_titles' : '',
        'permalink' : '/lyrics/oslo/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Race the Night',
        'appears_on_slug' : 'albums/race-the-night',
        'first_released' : 'September 15, 2023',
        'first_released_raw' : '2023-09-15',
        'unreleased' : false
    },
    {
        'id' : 295,
        'title' : 'Out of the Blue',
        'alternative_titles' : '',
        'permalink' : '/lyrics/out-of-the-blue/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler & Mark Hamilton',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Meltdown',
        'appears_on_slug' : 'albums/meltdown',
        'first_released' : 'May 17, 2004',
        'first_released_raw' : '2004-05-17',
        'unreleased' : false
    },
    {
        'id' : 296,
        'title' : 'Over & Out',
        'alternative_titles' : '',
        'permalink' : '/lyrics/over-and-out/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Race the Night',
        'appears_on_slug' : 'albums/race-the-night',
        'first_released' : 'September 15, 2023',
        'first_released_raw' : '2023-09-15',
        'unreleased' : false
    },
    {
        'id' : 297,
        'title' : 'Pacific Palisades',
        'alternative_titles' : '',
        'permalink' : '/lyrics/pacific-palisades/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Free All Angels',
        'appears_on_slug' : 'albums/free-all-angels',
        'first_released' : 'April 23, 2001',
        'first_released_raw' : '2001-04-23',
        'unreleased' : false
    },
    {
        'id' : 298,
        'title' : 'Palace of Excess',
        'alternative_titles' : 'Suicide Girls, Subterraneans',
        'permalink' : '/lyrics/palace-of-excess/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Twilight of the Innocents',
        'appears_on_slug' : 'albums/twilight-of-the-innocents',
        'first_released' : 'July 2, 2007',
        'first_released_raw' : '2007-07-02',
        'unreleased' : false
    },
    {
        'id' : 299,
        'title' : 'Paragon',
        'alternative_titles' : '',
        'permalink' : '/lyrics/paragon/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'Grey Will Fade',
        'appears_on_slug' : 'solo/grey-will-fade',
        'first_released' : 'August 16, 2004',
        'first_released_raw' : '2004-08-16',
        'unreleased' : false
    },
    {
        'id' : 300,
        'title' : 'Peanut Brain',
        'alternative_titles' : '',
        'permalink' : '/lyrics/peanut-brain/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Race the Night',
        'appears_on_slug' : 'albums/race-the-night',
        'first_released' : 'September 15, 2023',
        'first_released_raw' : '2023-09-15',
        'unreleased' : false
    },
    {
        'id' : 301,
        'title' : 'Petrol',
        'alternative_titles' : '',
        'permalink' : '/lyrics/petrol/',
        'songwriter' : 'Lyrics: Tim Wheeler. Music: Tim Wheeler & Mark Hamilton',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Trailer',
        'appears_on_slug' : 'eps/trailer',
        'first_released' : 'August 15, 1994',
        'first_released_raw' : '1994-08-15',
        'unreleased' : false
    },
    {
        'id' : 302,
        'title' : 'Physical World',
        'alternative_titles' : '',
        'permalink' : '/lyrics/physical-world/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Physical World - Single',
        'appears_on_slug' : 'a-z-singles-series/physical-world',
        'first_released' : 'June 7, 2010',
        'first_released_raw' : '2010-06-07',
        'unreleased' : false
    },
    {
        'id' : 303,
        'title' : 'Pirates Are So 2004',
        'alternative_titles' : '',
        'permalink' : '/lyrics/pirates-are-so-2004/',
        'songwriter' : 'Lyrics & Music: Mark Hamilton',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Pirates Are So 2004 - Single',
        'appears_on_slug' : 'a-z-singles-series/pirates-are-so-2004',
        'first_released' : 'May 4, 2010',
        'first_released_raw' : '2010-05-04',
        'unreleased' : false
    },
    {
        'id' : 304,
        'title' : 'Please Let That Be You',
        'alternative_titles' : '',
        'permalink' : '/lyrics/please-let-that-be-you/',
        'songwriter' : 'Lyrics & Music: Matt Sharp',
        'original_performer' : 'The Rentals',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Friends of P - The Rentals Tribute',
        'appears_on_slug' : '',
        'first_released' : 'March 25, 2008',
        'first_released_raw' : '2008-03-25',
        'unreleased' : false
    },
    {
        'id' : 305,
        'title' : 'Pledging My Love',
        'alternative_titles' : '',
        'permalink' : '/lyrics/pledging-my-love/',
        'songwriter' : 'Lyrics & Music: Ferdinand Washington and Don Robey',
        'original_performer' : 'Johnny Ace',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Little Infinity - EP',
        'appears_on_slug' : 'eps/little-infinity',
        'first_released' : 'June 16, 2012',
        'first_released_raw' : '2012-06-16',
        'unreleased' : false
    },
    {
        'id' : 306,
        'title' : 'Polaris',
        'alternative_titles' : 'Piano Song',
        'permalink' : '/lyrics/polaris/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Twilight of the Innocents',
        'appears_on_slug' : 'albums/twilight-of-the-innocents',
        'first_released' : 'June 11, 2007',
        'first_released_raw' : '2007-06-11',
        'unreleased' : false
    },
    {
        'id' : 307,
        'title' : 'Princess Six',
        'alternative_titles' : '',
        'permalink' : '/lyrics/princess-six/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Twilight of the Innocents',
        'appears_on_slug' : 'albums/twilight-of-the-innocents',
        'first_released' : 'July 2, 2007',
        'first_released_raw' : '2007-07-02',
        'unreleased' : false
    },
    {
        'id' : 308,
        'title' : 'Pripyat',
        'alternative_titles' : '',
        'permalink' : '/lyrics/pripyat/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Pripyat - Single',
        'appears_on_slug' : 'a-z-singles-series/pripyat',
        'first_released' : 'December 21, 2009',
        'first_released_raw' : '2009-12-21',
        'unreleased' : false
    },
    {
        'id' : 309,
        'title' : 'Projects',
        'alternative_titles' : 'Pickefoo',
        'permalink' : '/lyrics/projects/',
        'songwriter' : 'Lyrics: Tim Wheeler. Music: Mark Hamilton & Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Nu-Clear Sounds',
        'appears_on_slug' : 'albums/nu-clear-sounds',
        'first_released' : 'October 5, 1998',
        'first_released_raw' : '1998-10-05',
        'unreleased' : false
    },
    {
        'id' : 310,
        'title' : 'Punk Boy',
        'alternative_titles' : '',
        'permalink' : '/lyrics/punk-boy/',
        'songwriter' : 'Lyrics & Music: Helen Love',
        'original_performer' : 'Helen Love',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Trailer',
        'appears_on_slug' : 'eps/trailer',
        'first_released' : 'October 24, 1994',
        'first_released_raw' : '1994-10-24',
        'unreleased' : false
    },
    {
        'id' : 311,
        'title' : 'Race the Night',
        'alternative_titles' : '',
        'permalink' : '/lyrics/race-the-night/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Race the Night',
        'appears_on_slug' : 'albums/race-the-night',
        'first_released' : 'June 14, 2023',
        'first_released_raw' : '2023-06-14',
        'unreleased' : false
    },
    {
        'id' : 312,
        'title' : 'Radiation',
        'alternative_titles' : '',
        'permalink' : '/lyrics/radiation/',
        'songwriter' : 'Lyrics: Tim Wheeler. Music: Mark Hamilton',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Jesus Says - Single',
        'appears_on_slug' : 'singles/jesus-says',
        'first_released' : 'September 21, 1998',
        'first_released_raw' : '1998-09-21',
        'unreleased' : false
    },
    {
        'id' : 313,
        'title' : 'Raptor',
        'alternative_titles' : '',
        'permalink' : '/lyrics/raptor/',
        'songwriter' : 'Lyrics: Ash',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Home Demo',
        'appears_on_slug' : 'demos/home-demo',
        'first_released' : 'September 1, 1993',
        'first_released_raw' : '1993-09-01',
        'unreleased' : false
    },
    {
        'id' : 314,
        'title' : 'Renegade Cavalcade',
        'alternative_titles' : '',
        'permalink' : '/lyrics/renegade-cavalcade/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Meltdown',
        'appears_on_slug' : 'albums/meltdown',
        'first_released' : 'May 17, 2004',
        'first_released_raw' : '2004-05-17',
        'unreleased' : false
    },
    {
        'id' : 315,
        'title' : 'Replicants',
        'alternative_titles' : '',
        'permalink' : '/lyrics/replicants/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Replicants - Single',
        'appears_on_slug' : 'a-z-singles-series/replicants',
        'first_released' : 'August 24, 2010',
        'first_released_raw' : '2010-08-24',
        'unreleased' : false
    },
    {
        'id' : 316,
        'title' : 'Rescue Plan',
        'alternative_titles' : '',
        'permalink' : '/lyrics/rescue-plan/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'Grey Will Fade',
        'appears_on_slug' : 'solo/grey-will-fade',
        'first_released' : 'August 16, 2004',
        'first_released_raw' : '2004-08-16',
        'unreleased' : false
    },
    {
        'id' : 317,
        'title' : 'Return of White Rabbit',
        'alternative_titles' : '',
        'permalink' : '/lyrics/return-of-white-rabbit/',
        'songwriter' : 'Lyrics & Music: Mark Hamilton',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Return of White Rabbit - Single',
        'appears_on_slug' : 'a-z-singles-series/return-of-white-rabbit',
        'first_released' : 'May 18, 2009',
        'first_released_raw' : '2009-05-18',
        'unreleased' : false
    },
    {
        'id' : 318,
        'title' : 'Reward In Mind',
        'alternative_titles' : '',
        'permalink' : '/lyrics/reward-in-mind/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Race the Night',
        'appears_on_slug' : 'albums/race-the-night',
        'first_released' : 'September 15, 2023',
        'first_released_raw' : '2023-09-15',
        'unreleased' : false
    },
    {
        'id' : 319,
        'title' : 'Riad',
        'alternative_titles' : '',
        'permalink' : '/lyrics/riad/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : 'Tim Wheeler',
        'song_type' : 'Original',
        'appears_on' : 'Sheltered Youth - EP',
        'appears_on_slug' : 'solo/sheltered-youth',
        'first_released' : 'November 10, 2014',
        'first_released_raw' : '2014-11-10',
        'unreleased' : false
    },
    {
        'id' : 320,
        'title' : 'Rick’s Riff',
        'alternative_titles' : '',
        'permalink' : '/lyrics/ricks-riff/',
        'songwriter' : 'Lyrics: Tim Wheeler. Music: Rick McMurray',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Garage Girl - Demo tape',
        'appears_on_slug' : 'demos/garage-girl',
        'first_released' : 'November 1, 1992',
        'first_released_raw' : '1992-11-01',
        'unreleased' : false
    },
    {
        'id' : 321,
        'title' : 'Ritual',
        'alternative_titles' : 'Roulette, Keep It In',
        'permalink' : '/lyrics/ritual/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Twilight of the Innocents',
        'appears_on_slug' : 'albums/twilight-of-the-innocents',
        'first_released' : 'July 2, 2007',
        'first_released_raw' : '2007-07-02',
        'unreleased' : false
    },
    {
        'id' : 322,
        'title' : 'Rock N Roll Requires Repetition',
        'alternative_titles' : '',
        'permalink' : '/lyrics/rock-n-roll-requires-repetition/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Islands',
        'appears_on_slug' : 'albums/islands',
        'first_released' : 'May 18, 2018',
        'first_released_raw' : '2018-05-18',
        'unreleased' : false
    },
    {
        'id' : 323,
        'title' : 'Roll Over (Let It Go)',
        'alternative_titles' : '',
        'permalink' : '/lyrics/roll-over-let-it-go/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'The Deep Blue',
        'appears_on_slug' : 'solo/the-deep-blue',
        'first_released' : 'March 5, 2007',
        'first_released_raw' : '2007-03-05',
        'unreleased' : false
    },
    {
        'id' : 324,
        'title' : 'Rope Dancer',
        'alternative_titles' : '',
        'permalink' : '/lyrics/rope-dancer/',
        'songwriter' : 'Lyrics & Music: Ash',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 325,
        'title' : 'Running Away Again',
        'alternative_titles' : '',
        'permalink' : '/lyrics/running-away-again/',
        'songwriter' : 'Lyrics & Music: Ash',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 326,
        'title' : 'Running Back',
        'alternative_titles' : '',
        'permalink' : '/lyrics/running-back/',
        'songwriter' : 'Lyrics & Music: Phil Lynott',
        'original_performer' : 'Thin Lizzy',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 327,
        'title' : 'Running to the Ocean',
        'alternative_titles' : '',
        'permalink' : '/lyrics/running-to-the-ocean/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'A-Z Vol.2',
        'appears_on_slug' : 'compilations/a-z-vol-2',
        'first_released' : 'October 11, 2010',
        'first_released_raw' : '2010-10-11',
        'unreleased' : false
    },
    {
        'id' : 328,
        'title' : 'Santa Claus Is Comin’ To Town',
        'alternative_titles' : '',
        'permalink' : '/lyrics/santa-claus-is-comin-to-town/',
        'songwriter' : 'Lyrics & Music: J. Fred Coots & Haven Gillespie',
        'original_performer' : 'Harry Reser',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 329,
        'title' : 'Saskia',
        'alternative_titles' : '',
        'permalink' : '/lyrics/saskia/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'You Can’t Have It All - Single',
        'appears_on_slug' : 'singles/you-cant-have-it-all',
        'first_released' : 'April 16, 2007',
        'first_released_raw' : '2007-04-16',
        'unreleased' : false
    },
    {
        'id' : 330,
        'title' : 'Screamager',
        'alternative_titles' : '',
        'permalink' : '/lyrics/screamager/',
        'songwriter' : 'Lyrics & Music: Fyfe Ewing, Andy Cairns & Michael Robert Mckeegan',
        'original_performer' : 'Therapy?',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 331,
        'title' : 'Scum',
        'alternative_titles' : '',
        'permalink' : '/lyrics/scum/',
        'songwriter' : 'Lyrics & Music: Ash',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 332,
        'title' : 'Season',
        'alternative_titles' : '',
        'permalink' : '/lyrics/season/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Trailer',
        'appears_on_slug' : 'eps/trailer',
        'first_released' : 'October 17, 1994',
        'first_released_raw' : '1994-10-17',
        'unreleased' : false
    },
    {
        'id' : 333,
        'title' : 'See You Next Year',
        'alternative_titles' : '',
        'permalink' : '/lyrics/see-you-next-year/',
        'songwriter' : 'Lyrics & Music: Emma-Lee Moss & Tim Wheeler',
        'original_performer' : '',
        'project_name' : 'Emmy the Great & Tim Wheeler',
        'song_type' : 'Original',
        'appears_on' : 'This Is Christmas',
        'appears_on_slug' : 'solo/this-is-christmas',
        'first_released' : 'November 21, 2011',
        'first_released_raw' : '2011-11-21',
        'unreleased' : false
    },
    {
        'id' : 334,
        'title' : 'Send that Girl Back Home',
        'alternative_titles' : 'Argument',
        'permalink' : '/lyrics/send-that-girl-back-home/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 335,
        'title' : 'Sentimental Education',
        'alternative_titles' : '',
        'permalink' : '/lyrics/sentimental-education/',
        'songwriter' : 'Lyrics & Music: We Are Scientists',
        'original_performer' : 'We Are Scientists',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 336,
        'title' : 'Seventh Circle',
        'alternative_titles' : 'Singapore Song',
        'permalink' : '/lyrics/seventh-circle/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'End of the World - Single',
        'appears_on_slug' : 'singles/end-of-the-world',
        'first_released' : 'September 10, 2007',
        'first_released_raw' : '2007-09-10',
        'unreleased' : false
    },
    {
        'id' : 337,
        'title' : 'Shadows',
        'alternative_titles' : '',
        'permalink' : '/lyrics/shadows/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Twilight of the Innocents',
        'appears_on_slug' : 'albums/twilight-of-the-innocents',
        'first_released' : 'July 2, 2007',
        'first_released_raw' : '2007-07-02',
        'unreleased' : false
    },
    {
        'id' : 338,
        'title' : 'Shark',
        'alternative_titles' : '',
        'permalink' : '/lyrics/shark/',
        'songwriter' : 'Lyrics: Tim Wheeler. Music: Mark Hamilton & Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Free All Angels',
        'appears_on_slug' : 'albums/free-all-angels',
        'first_released' : 'April 23, 2001',
        'first_released_raw' : '2001-04-23',
        'unreleased' : false
    },
    {
        'id' : 339,
        'title' : 'Shattered Glass',
        'alternative_titles' : '',
        'permalink' : '/lyrics/shattered-glass/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Twilight of the Innocents',
        'appears_on_slug' : 'albums/twilight-of-the-innocents',
        'first_released' : 'July 2, 2007',
        'first_released_raw' : '2007-07-02',
        'unreleased' : false
    },
    {
        'id' : 340,
        'title' : 'Sheltered Youth',
        'alternative_titles' : '',
        'permalink' : '/lyrics/sheltered-youth/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : 'Tim Wheeler',
        'song_type' : 'Original',
        'appears_on' : 'Sheltered Youth - EP',
        'appears_on_slug' : 'solo/sheltered-youth',
        'first_released' : 'November 10, 2014',
        'first_released_raw' : '2014-11-10',
        'unreleased' : false
    },
    {
        'id' : 341,
        'title' : 'Shining Light',
        'alternative_titles' : '',
        'permalink' : '/lyrics/shining-light/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Free All Angels',
        'appears_on_slug' : 'albums/free-all-angels',
        'first_released' : 'January 29, 2001',
        'first_released_raw' : '2001-01-29',
        'unreleased' : false
    },
    {
        'id' : 342,
        'title' : 'Shockwave',
        'alternative_titles' : '',
        'permalink' : '/lyrics/shockwave/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Renegade Cavalcade - Single',
        'appears_on_slug' : 'singles/renegade-cavalcade',
        'first_released' : 'December 6, 2004',
        'first_released_raw' : '2004-12-06',
        'unreleased' : false
    },
    {
        'id' : 343,
        'title' : 'Shutdown',
        'alternative_titles' : '',
        'permalink' : '/lyrics/shutdown/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Kablammo!',
        'appears_on_slug' : 'albums/kablammo',
        'first_released' : 'May 25, 2015',
        'first_released_raw' : '2015-05-25',
        'unreleased' : false
    },
    {
        'id' : 344,
        'title' : 'Siberia',
        'alternative_titles' : '',
        'permalink' : '/lyrics/siberia/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'The Deep Blue',
        'appears_on_slug' : 'solo/the-deep-blue',
        'first_released' : 'March 5, 2007',
        'first_released_raw' : '2007-03-05',
        'unreleased' : false
    },
    {
        'id' : 345,
        'title' : 'Sick Party',
        'alternative_titles' : '',
        'permalink' : '/lyrics/sick-party/',
        'songwriter' : '',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : '1977',
        'appears_on_slug' : 'albums/1977',
        'first_released' : 'May 6, 1996',
        'first_released_raw' : '1996-05-06',
        'unreleased' : false
    },
    {
        'id' : 346,
        'title' : 'Silver Suit',
        'alternative_titles' : '',
        'permalink' : '/lyrics/silver-suit/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Islands',
        'appears_on_slug' : 'albums/islands',
        'first_released' : 'May 18, 2018',
        'first_released_raw' : '2018-05-18',
        'unreleased' : false
    },
    {
        'id' : 347,
        'title' : 'Silver Surfer',
        'alternative_titles' : '',
        'permalink' : '/lyrics/silver-surfer/',
        'songwriter' : 'Lyrics & Music: Barry Peak & Boyd Lowe',
        'original_performer' : 'Backwater',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'BBC Sessions 1994-1999',
        'appears_on_slug' : 'compilations/bbc-sessions-1994-1999',
        'first_released' : 'October 17, 1994',
        'first_released_raw' : '1994-10-17',
        'unreleased' : false
    },
    {
        'id' : 348,
        'title' : 'Skullfull of Sulphur',
        'alternative_titles' : '',
        'permalink' : '/lyrics/skullfull-of-sulphur/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Sometimes - Single',
        'appears_on_slug' : 'singles/sometimes',
        'first_released' : 'July 9, 2001',
        'first_released_raw' : '2001-07-09',
        'unreleased' : false
    },
    {
        'id' : 349,
        'title' : 'Sky Burial',
        'alternative_titles' : '',
        'permalink' : '/lyrics/sky-burial/',
        'songwriter' : 'Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Sky Burial - Single',
        'appears_on_slug' : 'a-z-singles-series/sky-burial',
        'first_released' : 'September 13, 2010',
        'first_released_raw' : '2010-09-13',
        'unreleased' : false
    },
    {
        'id' : 350,
        'title' : 'Sleigh Me',
        'alternative_titles' : '',
        'permalink' : '/lyrics/sleigh-me/',
        'songwriter' : 'Lyrics & Music: Emma-Lee Moss & Tim Wheeler',
        'original_performer' : '',
        'project_name' : 'Emmy the Great & Tim Wheeler',
        'song_type' : 'Original',
        'appears_on' : 'This Is Christmas',
        'appears_on_slug' : 'solo/this-is-christmas',
        'first_released' : 'November 21, 2011',
        'first_released_raw' : '2011-11-21',
        'unreleased' : false
    },
    {
        'id' : 351,
        'title' : 'Sleigh Ride',
        'alternative_titles' : '',
        'permalink' : '/lyrics/sleigh-ride/',
        'songwriter' : 'Lyrics: Mitchell Parish. Music: Leroy Anderson',
        'original_performer' : 'Arthur Fiedler and the Boston Pops Orchestra',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 352,
        'title' : 'Sneaker',
        'alternative_titles' : '',
        'permalink' : '/lyrics/sneaker/',
        'songwriter' : 'Lyrics & Music: Mark Hamilton & Barry Peak',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Goldfinger - Single',
        'appears_on_slug' : 'singles/goldfinger',
        'first_released' : 'April 15, 1996',
        'first_released_raw' : '1996-04-15',
        'unreleased' : false
    },
    {
        'id' : 353,
        'title' : 'Snow in Nara',
        'alternative_titles' : '',
        'permalink' : '/lyrics/snow-in-nara/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : 'Tim Wheeler',
        'song_type' : 'Original',
        'appears_on' : 'Lost Domain',
        'appears_on_slug' : 'solo/lost-domain',
        'first_released' : 'November 10, 2014',
        'first_released_raw' : '2014-11-10',
        'unreleased' : false
    },
    {
        'id' : 354,
        'title' : 'Snowflakes',
        'alternative_titles' : '',
        'permalink' : '/lyrics/snowflakes/',
        'songwriter' : 'Lyrics & Music: Emma-Lee Moss & Tim Wheeler',
        'original_performer' : '',
        'project_name' : 'Emmy the Great & Tim Wheeler',
        'song_type' : 'Original',
        'appears_on' : 'This Is Christmas',
        'appears_on_slug' : 'solo/this-is-christmas',
        'first_released' : 'November 21, 2011',
        'first_released_raw' : '2011-11-21',
        'unreleased' : false
    },
    {
        'id' : 355,
        'title' : 'So the Story Goes',
        'alternative_titles' : '',
        'permalink' : '/lyrics/so-the-story-goes/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Sometimes - Single',
        'appears_on_slug' : 'singles/sometimes',
        'first_released' : 'July 9, 2001',
        'first_released_raw' : '2001-07-09',
        'unreleased' : false
    },
    {
        'id' : 356,
        'title' : 'Solace',
        'alternative_titles' : 'Buffalo Song',
        'permalink' : '/lyrics/solace/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Starcrossed - Single',
        'appears_on_slug' : 'singles/starcrossed',
        'first_released' : 'July 19, 2004',
        'first_released_raw' : '2004-07-19',
        'unreleased' : false
    },
    {
        'id' : 357,
        'title' : 'Solar Happy',
        'alternative_titles' : '',
        'permalink' : '/lyrics/solar-happy/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Solar Happy - Demo',
        'appears_on_slug' : 'demos/solar-happy',
        'first_released' : 'June 29, 1992',
        'first_released_raw' : '1992-06-29',
        'unreleased' : false
    },
    {
        'id' : 358,
        'title' : 'Someday',
        'alternative_titles' : '',
        'permalink' : '/lyrics/someday/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Free All Angels',
        'appears_on_slug' : 'albums/free-all-angels',
        'first_released' : 'April 23, 2001',
        'first_released_raw' : '2001-04-23',
        'unreleased' : false
    },
    {
        'id' : 359,
        'title' : 'Somersault',
        'alternative_titles' : '',
        'permalink' : '/lyrics/somersault/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Islands',
        'appears_on_slug' : 'albums/islands',
        'first_released' : 'May 18, 2018',
        'first_released_raw' : '2018-05-18',
        'unreleased' : false
    },
    {
        'id' : 360,
        'title' : 'Something Like You',
        'alternative_titles' : '',
        'permalink' : '/lyrics/something-like-you/',
        'songwriter' : 'Lyrics & Music: Michael Head',
        'original_performer' : 'Michael Head introducing The Strands',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'BBC Sessions 1994-1999',
        'appears_on_slug' : 'compilations/bbc-sessions-1994-1999',
        'first_released' : 'July 17, 2021',
        'first_released_raw' : '2021-07-17',
        'unreleased' : false
    },
    {
        'id' : 361,
        'title' : 'Sometimes',
        'alternative_titles' : '',
        'permalink' : '/lyrics/sometimes/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Free All Angels',
        'appears_on_slug' : 'albums/free-all-angels',
        'first_released' : 'April 23, 2001',
        'first_released_raw' : '2001-04-23',
        'unreleased' : false
    },
    {
        'id' : 362,
        'title' : 'Song of Your Desire',
        'alternative_titles' : '',
        'permalink' : '/lyrics/song-of-your-desire/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Song of Your Desire - Single',
        'appears_on_slug' : 'a-z-singles-series/song-of-your-desire',
        'first_released' : 'March 1, 2010',
        'first_released_raw' : '2010-03-01',
        'unreleased' : false
    },
    {
        'id' : 363,
        'title' : 'Sorry My Friend',
        'alternative_titles' : '',
        'permalink' : '/lyrics/sorry-my-friend/',
        'songwriter' : 'Lyrics & Music: Ash',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Solar Happy - Demo',
        'appears_on_slug' : 'demos/solar-happy',
        'first_released' : 'June 29, 1992',
        'first_released_raw' : '1992-06-29',
        'unreleased' : false
    },
    {
        'id' : 364,
        'title' : 'Space Shot',
        'alternative_titles' : '',
        'permalink' : '/lyrics/space-shot/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Space Shot - Single',
        'appears_on_slug' : 'a-z-singles-series/space-shot',
        'first_released' : 'January 18, 2010',
        'first_released_raw' : '2010-01-18',
        'unreleased' : false
    },
    {
        'id' : 365,
        'title' : 'Spellbound',
        'alternative_titles' : 'Ice Cold',
        'permalink' : '/lyrics/spellbound/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'A-Z Vol.2',
        'appears_on_slug' : 'compilations/a-z-vol-2',
        'first_released' : 'October 11, 2010',
        'first_released_raw' : '2010-10-11',
        'unreleased' : false
    },
    {
        'id' : 366,
        'title' : 'Spheres',
        'alternative_titles' : '',
        'permalink' : '/lyrics/spheres/',
        'songwriter' : 'Lyrics: Tim Wheeler. Music: Mark Hamilton and Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Spheres - Single',
        'appears_on_slug' : 'a-z-singles-series/spheres',
        'first_released' : 'June 21, 2010',
        'first_released_raw' : '2010-06-21',
        'unreleased' : false
    },
    {
        'id' : 367,
        'title' : 'Stages',
        'alternative_titles' : '',
        'permalink' : '/lyrics/stages/',
        'songwriter' : 'Lyrics & Music: The Frank and Walters',
        'original_performer' : 'The Frank and Walters',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 368,
        'title' : 'Stand by Me',
        'alternative_titles' : '',
        'permalink' : '/lyrics/stand-by-me/',
        'songwriter' : 'Lyrics & Music: Ben E. King, Jerry Leiber and Mike Stoller',
        'original_performer' : 'Ben E. King',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 369,
        'title' : 'Starcrossed',
        'alternative_titles' : '',
        'permalink' : '/lyrics/starcrossed/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Meltdown',
        'appears_on_slug' : 'albums/meltdown',
        'first_released' : 'May 17, 2004',
        'first_released_raw' : '2004-05-17',
        'unreleased' : false
    },
    {
        'id' : 370,
        'title' : 'Starry Eyes',
        'alternative_titles' : '',
        'permalink' : '/lyrics/starry-eyes/',
        'songwriter' : 'Lyrics & Music: Roky Erickson',
        'original_performer' : 'Roky Erickson',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 371,
        'title' : 'Statis in Darkness',
        'alternative_titles' : '',
        'permalink' : '/lyrics/statis-in-darkness/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'End of the World - Single',
        'appears_on_slug' : 'singles/end-of-the-world',
        'first_released' : 'September 10, 2007',
        'first_released_raw' : '2007-09-10',
        'unreleased' : false
    },
    {
        'id' : 372,
        'title' : 'Stay Another Day',
        'alternative_titles' : '',
        'permalink' : '/lyrics/stay-another-day/',
        'songwriter' : 'Lyrics & Music: Tony Mortimer, Rob Kean and Dominic Hawken',
        'original_performer' : 'East 17',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 373,
        'title' : 'Stay in Love Forever',
        'alternative_titles' : '',
        'permalink' : '/lyrics/stay-in-love-forever/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Candy - Single',
        'appears_on_slug' : 'singles/candy',
        'first_released' : 'October 1, 2001',
        'first_released_raw' : '2001-10-01',
        'unreleased' : false
    },
    {
        'id' : 374,
        'title' : 'Step into Christmas',
        'alternative_titles' : '',
        'permalink' : '/lyrics/step-into-christmas/',
        'songwriter' : 'Lyrics & Music: Elton John and Bernie Taupin',
        'original_performer' : 'Elton John',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 375,
        'title' : 'Stoned Party',
        'alternative_titles' : '',
        'permalink' : '/lyrics/stoned-party/',
        'songwriter' : 'Lyrics & Music: Ash',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 376,
        'title' : 'Stop',
        'alternative_titles' : '',
        'permalink' : '/lyrics/stop/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'Grey Will Fade',
        'appears_on_slug' : 'solo/grey-will-fade',
        'first_released' : 'August 16, 2004',
        'first_released_raw' : '2004-08-16',
        'unreleased' : false
    },
    {
        'id' : 377,
        'title' : 'Stormy Waters',
        'alternative_titles' : 'Ricky IV',
        'permalink' : '/lyrics/stormy-waters/',
        'songwriter' : 'Lyrics & Music: Rick McMurray',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Wild Surf - Single',
        'appears_on_slug' : 'singles/wild-surf',
        'first_released' : 'November 23, 1998',
        'first_released_raw' : '1998-11-23',
        'unreleased' : false
    },
    {
        'id' : 378,
        'title' : 'Straight Lines',
        'alternative_titles' : '',
        'permalink' : '/lyrics/straight-lines/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'New Worlds',
        'appears_on_slug' : 'solo/new-worlds',
        'first_released' : 'October 16, 2009',
        'first_released_raw' : '2009-10-16',
        'unreleased' : false
    },
    {
        'id' : 379,
        'title' : 'Submission',
        'alternative_titles' : '',
        'permalink' : '/lyrics/submission/',
        'songwriter' : 'Lyrics by: Rick McMurray & Tim Wheeler. Music by: Rick McMurray',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Free All Angels',
        'appears_on_slug' : 'albums/free-all-angels',
        'first_released' : 'April 23, 2001',
        'first_released_raw' : '2001-04-23',
        'unreleased' : false
    },
    {
        'id' : 380,
        'title' : 'Summer',
        'alternative_titles' : '',
        'permalink' : '/lyrics/summer/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'Grey Will Fade',
        'appears_on_slug' : 'solo/grey-will-fade',
        'first_released' : 'August 16, 2004',
        'first_released_raw' : '2004-08-16',
        'unreleased' : false
    },
    {
        'id' : 381,
        'title' : 'Summer Snow',
        'alternative_titles' : '',
        'permalink' : '/lyrics/summer-snow/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Summer Snow - Single',
        'appears_on_slug' : 'a-z-singles-series/summer-snow',
        'first_released' : 'July 19, 2010',
        'first_released_raw' : '2010-07-19',
        'unreleased' : false
    },
    {
        'id' : 382,
        'title' : 'Sweet Child o’ Mine',
        'alternative_titles' : '',
        'permalink' : '/lyrics/sweet-child-o-mine/',
        'songwriter' : 'Lyrics & Music: Guns N’ Roses',
        'original_performer' : 'Guns N’ Roses',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 383,
        'title' : 'T-Rex',
        'alternative_titles' : '',
        'permalink' : '/lyrics/t-rex/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Oh Yeah - Single',
        'appears_on_slug' : 'singles/oh-yeah',
        'first_released' : 'June 24, 1996',
        'first_released_raw' : '1996-06-24',
        'unreleased' : false
    },
    {
        'id' : 384,
        'title' : 'Taken Out',
        'alternative_titles' : 'NTT Rap',
        'permalink' : '/lyrics/taken-out/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Jesus Says - Single',
        'appears_on_slug' : 'singles/jesus-says',
        'first_released' : 'September 21, 1998',
        'first_released_raw' : '1998-09-21',
        'unreleased' : false
    },
    {
        'id' : 385,
        'title' : 'Teenage Kicks',
        'alternative_titles' : '',
        'permalink' : '/lyrics/teenage-kicks/',
        'songwriter' : 'Lyrics & Music: John O’Neill',
        'original_performer' : 'The Undertones',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Sometimes - Single',
        'appears_on_slug' : 'singles/sometimes',
        'first_released' : 'July 9, 2001',
        'first_released_raw' : '2001-07-09',
        'unreleased' : false
    },
    {
        'id' : 386,
        'title' : 'Teenage Wildlife',
        'alternative_titles' : '',
        'permalink' : '/lyrics/teenage-wildlife/',
        'songwriter' : 'Lyrics & Music: David Bowie',
        'original_performer' : 'David Bowie',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'A-Z Vol.2',
        'appears_on_slug' : 'compilations/a-z-vol-2',
        'first_released' : 'October 11, 2010',
        'first_released_raw' : '2010-10-11',
        'unreleased' : false
    },
    {
        'id' : 387,
        'title' : 'Terraform',
        'alternative_titles' : '',
        'permalink' : '/lyrics/terraform/',
        'songwriter' : 'Lyrics & Music: Mark Hamilton',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Kablammo!',
        'appears_on_slug' : 'albums/kablammo',
        'first_released' : 'May 25, 2015',
        'first_released_raw' : '2015-05-25',
        'unreleased' : false
    },
    {
        'id' : 388,
        'title' : 'Territorial Pissings',
        'alternative_titles' : '',
        'permalink' : '/lyrics/territorial-pissings/',
        'songwriter' : 'Lyrics & Music: Kurt Cobain',
        'original_performer' : 'Nirvana',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 389,
        'title' : 'The Anarchist',
        'alternative_titles' : '',
        'permalink' : '/lyrics/the-anarchist/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler & Mark Hamilton',
        'original_performer' : '',
        'project_name' : 'Vietnam',
        'song_type' : 'Original',
        'appears_on' : 'Vietnam - Demo tape',
        'appears_on_slug' : 'demos/vietnam',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 390,
        'title' : 'The Beautiful People',
        'alternative_titles' : '',
        'permalink' : '/lyrics/the-beautiful-people/',
        'songwriter' : 'Lyrics & Music: Jeordie White and Marilyn Manson',
        'original_performer' : 'Marilyn Manson',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 391,
        'title' : 'The Boys Are Back in Town',
        'alternative_titles' : '',
        'permalink' : '/lyrics/the-boys-are-back-in-town/',
        'songwriter' : 'Lyrics & Music: Phil Lynott',
        'original_performer' : 'Thin Lizzy',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 392,
        'title' : 'The Concept',
        'alternative_titles' : '',
        'permalink' : '/lyrics/the-concept/',
        'songwriter' : 'Lyrics & Music: Norman George Blake',
        'original_performer' : 'Teenage Fanclub',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 393,
        'title' : 'The Creeps',
        'alternative_titles' : '',
        'permalink' : '/lyrics/the-creeps/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'A-Z Vol.1',
        'appears_on_slug' : 'compilations/a-z-vol-1',
        'first_released' : 'April 19, 2010',
        'first_released_raw' : '2010-04-19',
        'unreleased' : false
    },
    {
        'id' : 394,
        'title' : 'The Dead Disciples',
        'alternative_titles' : '',
        'permalink' : '/lyrics/the-dead-disciples/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'The Dead Disciples - Single',
        'appears_on_slug' : 'a-z-singles-series/the-dead-disciples',
        'first_released' : 'December 7, 2009',
        'first_released_raw' : '2009-12-07',
        'unreleased' : false
    },
    {
        'id' : 395,
        'title' : 'The Great Escape',
        'alternative_titles' : '',
        'permalink' : '/lyrics/the-great-escape/',
        'songwriter' : 'Lyrics & Music: We Are Scientists',
        'original_performer' : 'We Are Scientists',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 396,
        'title' : 'The Holiday Song',
        'alternative_titles' : '',
        'permalink' : '/lyrics/the-holiday-song/',
        'songwriter' : 'Lyrics & Music: Black Francis',
        'original_performer' : 'Pixies',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 397,
        'title' : 'The Little Pond',
        'alternative_titles' : '',
        'permalink' : '/lyrics/the-little-pond/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Petrol - Single',
        'appears_on_slug' : 'singles/petrol',
        'first_released' : 'August 15, 1994',
        'first_released_raw' : '1994-08-15',
        'unreleased' : false
    },
    {
        'id' : 398,
        'title' : 'The Scream',
        'alternative_titles' : '',
        'permalink' : '/lyrics/the-scream/',
        'songwriter' : 'Lyrics & Music: Ash',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : '1977 (Deluxe Edition)',
        'appears_on_slug' : 'compilations/1977-deluxe-edition',
        'first_released' : 'November 3, 2008',
        'first_released_raw' : '2008-11-03',
        'unreleased' : false
    },
    {
        'id' : 399,
        'title' : 'The Sweetness of Death by the Obsidian Knife',
        'alternative_titles' : '',
        'permalink' : '/lyrics/the-sweetness-of-death-by-the-obsidian-knife/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Candy - Single',
        'appears_on_slug' : 'singles/candy',
        'first_released' : 'October 1, 2001',
        'first_released_raw' : '2001-10-01',
        'unreleased' : false
    },
    {
        'id' : 400,
        'title' : 'There Is a Light That Never Goes Out',
        'alternative_titles' : '',
        'permalink' : '/lyrics/there-is-a-light-that-never-goes-out/',
        'songwriter' : 'Lyrics & Music: Morrissey & Johnny Marr',
        'original_performer' : 'The Smiths',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 401,
        'title' : 'There Is Hope Again',
        'alternative_titles' : '',
        'permalink' : '/lyrics/there-is-hope-again/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'There Is Hope Again - Single',
        'appears_on_slug' : 'a-z-singles-series/there-is-hope-again',
        'first_released' : 'September 27, 2010',
        'first_released_raw' : '2010-09-27',
        'unreleased' : false
    },
    {
        'id' : 402,
        'title' : 'There’s a Star',
        'alternative_titles' : '',
        'permalink' : '/lyrics/theres-a-star/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Free All Angels',
        'appears_on_slug' : 'albums/free-all-angels',
        'first_released' : 'April 23, 2001',
        'first_released_raw' : '2001-04-23',
        'unreleased' : false
    },
    {
        'id' : 403,
        'title' : 'Thin Air',
        'alternative_titles' : '',
        'permalink' : '/lyrics/thin-air/',
        'songwriter' : 'Lyrics & Music: Ash',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 404,
        'title' : 'Things',
        'alternative_titles' : '',
        'permalink' : '/lyrics/things/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Petrol - Single',
        'appears_on_slug' : 'singles/petrol',
        'first_released' : 'August 15, 1994',
        'first_released_raw' : '1994-08-15',
        'unreleased' : false
    },
    {
        'id' : 405,
        'title' : 'Thinking About You',
        'alternative_titles' : '',
        'permalink' : '/lyrics/thinking-about-you/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Burn Baby Burn - Single',
        'appears_on_slug' : 'singles/burn-baby-burn',
        'first_released' : 'April 2, 2001',
        'first_released_raw' : '2001-04-02',
        'unreleased' : false
    },
    {
        'id' : 406,
        'title' : 'This Charming Man',
        'alternative_titles' : '',
        'permalink' : '/lyrics/this-charming-man/',
        'songwriter' : 'Lyrics & Music: Morrissey & Johnny Marr',
        'original_performer' : 'The Smiths',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 407,
        'title' : 'This Is Christmas Intro',
        'alternative_titles' : '',
        'permalink' : '/lyrics/this-is-christmas-intro/',
        'songwriter' : 'Music: Ilan Eshkeri',
        'original_performer' : '',
        'project_name' : 'Emmy the Great & Tim Wheeler',
        'song_type' : 'Original',
        'appears_on' : 'This Is Christmas',
        'appears_on_slug' : 'solo/this-is-christmas',
        'first_released' : 'November 21, 2011',
        'first_released_raw' : '2011-11-21',
        'unreleased' : false
    },
    {
        'id' : 408,
        'title' : 'This Time Tomorrow',
        'alternative_titles' : '',
        'permalink' : '/lyrics/this-time-tomorrow/',
        'songwriter' : 'Lyrics & Music: Ray Davies',
        'original_performer' : 'The Kinks',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Kablammo!',
        'appears_on_slug' : 'albums/kablammo',
        'first_released' : 'May 25, 2015',
        'first_released_raw' : '2015-05-25',
        'unreleased' : false
    },
    {
        'id' : 409,
        'title' : 'Time Space Heartache',
        'alternative_titles' : '',
        'permalink' : '/lyrics/time-space-heartache/',
        'songwriter' : 'Lyrics & Music: Ash',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 410,
        'title' : 'Tinseltown',
        'alternative_titles' : '',
        'permalink' : '/lyrics/tinseltown/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Orpheus - Single',
        'appears_on_slug' : 'singles/orpheus',
        'first_released' : 'May 3, 2004',
        'first_released_raw' : '2004-05-03',
        'unreleased' : false
    },
    {
        'id' : 411,
        'title' : 'Tonight You Belong to Me',
        'alternative_titles' : '',
        'permalink' : '/lyrics/tonight-you-belong-to-me/',
        'songwriter' : 'Lyrics: Barry Rose. Music: Lee David',
        'original_performer' : 'Irving Kaufman',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Envy - Single',
        'appears_on_slug' : 'singles/envy',
        'first_released' : 'August 26, 2002',
        'first_released_raw' : '2002-08-26',
        'unreleased' : false
    },
    {
        'id' : 412,
        'title' : 'Touch Me I’m Sick',
        'alternative_titles' : '',
        'permalink' : '/lyrics/touch-me-im-sick/',
        'songwriter' : 'Lyrics & Music: Mudhoney',
        'original_performer' : 'Mudhoney',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 413,
        'title' : 'Tracers',
        'alternative_titles' : 'World Without You, Tracer (Without You)',
        'permalink' : '/lyrics/tracers/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Tracers - Single',
        'appears_on_slug' : 'a-z-singles-series/tracers',
        'first_released' : 'November 23, 2009',
        'first_released_raw' : '2009-11-23',
        'unreleased' : false
    },
    {
        'id' : 414,
        'title' : 'Trenchcoat',
        'alternative_titles' : '',
        'permalink' : '/lyrics/trenchcoat/',
        'songwriter' : 'Lyrics & Music: Ash',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 415,
        'title' : 'True Love',
        'alternative_titles' : '',
        'permalink' : '/lyrics/true-love/',
        'songwriter' : 'Music: Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'True Love',
        'appears_on_slug' : 'solo/true-love',
        'first_released' : 'May 16, 2017',
        'first_released_raw' : '2017-05-16',
        'unreleased' : false
    },
    {
        'id' : 416,
        'title' : 'True Love 1980',
        'alternative_titles' : '',
        'permalink' : '/lyrics/true-love-1980/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'True Love 1980 - Single',
        'appears_on_slug' : 'a-z-singles-series/true-love-1980',
        'first_released' : 'October 12, 2009',
        'first_released_raw' : '2009-10-12',
        'unreleased' : false
    },
    {
        'id' : 417,
        'title' : 'True Story',
        'alternative_titles' : '',
        'permalink' : '/lyrics/true-story/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Islands',
        'appears_on_slug' : 'albums/islands',
        'first_released' : 'May 18, 2018',
        'first_released_raw' : '2018-05-18',
        'unreleased' : false
    },
    {
        'id' : 418,
        'title' : 'Twilight of the Innocents',
        'alternative_titles' : 'Korg, The Boy Who Lived in Hell, In Hell',
        'permalink' : '/lyrics/twilight-of-the-innocents/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Twilight of the Innocents',
        'appears_on_slug' : 'albums/twilight-of-the-innocents',
        'first_released' : 'July 2, 2007',
        'first_released_raw' : '2007-07-02',
        'unreleased' : false
    },
    {
        'id' : 419,
        'title' : 'Two',
        'alternative_titles' : '',
        'permalink' : '/lyrics/two/',
        'songwriter' : 'Lyrics & Music: Ash',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Solar Happy - Demo',
        'appears_on_slug' : 'demos/solar-happy',
        'first_released' : 'June 29, 1992',
        'first_released_raw' : '1992-06-29',
        'unreleased' : false
    },
    {
        'id' : 420,
        'title' : 'Uncle Pat',
        'alternative_titles' : '',
        'permalink' : '/lyrics/uncle-pat/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Trailer',
        'appears_on_slug' : 'eps/trailer',
        'first_released' : 'October 17, 1994',
        'first_released_raw' : '1994-10-17',
        'unreleased' : false
    },
    {
        'id' : 421,
        'title' : 'Undone - The Sweater Song',
        'alternative_titles' : '',
        'permalink' : '/lyrics/undone-the-sweater-song/',
        'songwriter' : 'Lyrics & Music: Rivers Cuomo',
        'original_performer' : 'Weezer',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 422,
        'title' : 'Unlike You',
        'alternative_titles' : '',
        'permalink' : '/lyrics/unlike-you/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Garage Girl - Demo tape',
        'appears_on_slug' : 'demos/garage-girl',
        'first_released' : 'February 1, 1993',
        'first_released_raw' : '1993-02-01',
        'unreleased' : false
    },
    {
        'id' : 423,
        'title' : 'Uptown Girl',
        'alternative_titles' : '',
        'permalink' : '/lyrics/uptown-girl/',
        'songwriter' : 'Lyrics & Music: Billy Joel',
        'original_performer' : 'Billy Joel',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 424,
        'title' : 'Usual Places',
        'alternative_titles' : '',
        'permalink' : '/lyrics/usual-places/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Race the Night',
        'appears_on_slug' : 'albums/race-the-night',
        'first_released' : 'August 15, 2023',
        'first_released_raw' : '2023-08-15',
        'unreleased' : false
    },
    {
        'id' : 425,
        'title' : 'Vampire Love',
        'alternative_titles' : '',
        'permalink' : '/lyrics/vampire-love/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Meltdown',
        'appears_on_slug' : 'albums/meltdown',
        'first_released' : 'May 17, 2004',
        'first_released_raw' : '2004-05-17',
        'unreleased' : false
    },
    {
        'id' : 426,
        'title' : 'Vapour',
        'alternative_titles' : '',
        'permalink' : '/lyrics/vapour/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : 'Tim Wheeler',
        'song_type' : 'Original',
        'appears_on' : 'Lost Domain',
        'appears_on_slug' : 'solo/lost-domain',
        'first_released' : 'November 10, 2014',
        'first_released_raw' : '2014-11-10',
        'unreleased' : false
    },
    {
        'id' : 427,
        'title' : 'Velouria',
        'alternative_titles' : '',
        'permalink' : '/lyrics/velouria/',
        'songwriter' : 'Lyrics & Music: Black Francis',
        'original_performer' : 'Pixies',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 428,
        'title' : 'Very Young',
        'alternative_titles' : '',
        'permalink' : '/lyrics/very-young/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'The Deep Blue',
        'appears_on_slug' : 'solo/the-deep-blue',
        'first_released' : 'March 5, 2007',
        'first_released_raw' : '2007-03-05',
        'unreleased' : false
    },
    {
        'id' : 429,
        'title' : 'Vietnam',
        'alternative_titles' : '',
        'permalink' : '/lyrics/vietnam/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler & Mark Hamilton',
        'original_performer' : '',
        'project_name' : 'Vietnam',
        'song_type' : 'Original',
        'appears_on' : 'Vietnam - Demo tape',
        'appears_on_slug' : 'demos/vietnam',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 430,
        'title' : 'Vigil',
        'alternative_titles' : '',
        'permalink' : '/lyrics/vigil/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : 'Tim Wheeler',
        'song_type' : 'Original',
        'appears_on' : 'Lost Domain',
        'appears_on_slug' : 'solo/lost-domain',
        'first_released' : 'November 10, 2014',
        'first_released_raw' : '2014-11-10',
        'unreleased' : false
    },
    {
        'id' : 431,
        'title' : 'Walking Barefoot',
        'alternative_titles' : '',
        'permalink' : '/lyrics/walking-barefoot/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Free All Angels',
        'appears_on_slug' : 'albums/free-all-angels',
        'first_released' : 'April 23, 2001',
        'first_released_raw' : '2001-04-23',
        'unreleased' : false
    },
    {
        'id' : 432,
        'title' : 'War With Me',
        'alternative_titles' : '',
        'permalink' : '/lyrics/war-with-me/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'War With Me - Single',
        'appears_on_slug' : 'a-z-singles-series/war-with-me',
        'first_released' : 'March 29, 2010',
        'first_released_raw' : '2010-03-29',
        'unreleased' : false
    },
    {
        'id' : 433,
        'title' : 'Warmer Than Fire',
        'alternative_titles' : '',
        'permalink' : '/lyrics/warmer-than-fire/',
        'songwriter' : 'Lyrics & Music: Steve Ludwin',
        'original_performer' : 'Little Hell',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Warmer Than Fire',
        'appears_on_slug' : 'singles/warmer-than-fire',
        'first_released' : 'January 29, 2001',
        'first_released_raw' : '2001-01-29',
        'unreleased' : false
    },
    {
        'id' : 434,
        'title' : 'Washington Parks',
        'alternative_titles' : '',
        'permalink' : '/lyrics/washington-parks/',
        'songwriter' : 'Lyrics & Music: Robert Manning',
        'original_performer' : 'Robert Manning',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Washington Parks',
        'appears_on_slug' : 'solo/washington-parks',
        'first_released' : 'May 15, 2011',
        'first_released_raw' : '2011-05-15',
        'unreleased' : false
    },
    {
        'id' : 435,
        'title' : 'Wasted on You',
        'alternative_titles' : '',
        'permalink' : '/lyrics/wasted-on-you/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'End of the World - Single',
        'appears_on_slug' : 'singles/end-of-the-world',
        'first_released' : 'September 10, 2007',
        'first_released_raw' : '2007-09-10',
        'unreleased' : false
    },
    {
        'id' : 436,
        'title' : 'Waterfall',
        'alternative_titles' : '',
        'permalink' : '/lyrics/waterfall/',
        'songwriter' : 'Lyrics: Tim Wheeler. Music: Mark Hamilton, Rick McMurray & Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Candy - Single',
        'appears_on_slug' : 'singles/candy',
        'first_released' : 'October 1, 2001',
        'first_released_raw' : '2001-10-01',
        'unreleased' : false
    },
    {
        'id' : 437,
        'title' : 'Wave of Mutilation',
        'alternative_titles' : '',
        'permalink' : '/lyrics/wave-of-mutilation/',
        'songwriter' : 'Lyrics & Music: Black Francis',
        'original_performer' : 'Pixies',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 438,
        'title' : 'Way I’m Feeling',
        'alternative_titles' : '',
        'permalink' : '/lyrics/way-im-feeling/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : 'Tim Wheeler',
        'song_type' : 'Original',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'September 2, 2014',
        'first_released_raw' : '2014-09-02',
        'unreleased' : false
    },
    {
        'id' : 439,
        'title' : 'We Don’t Care',
        'alternative_titles' : '',
        'permalink' : '/lyrics/we-dont-care/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler and Mik Tierney',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Renegade Cavalcade - Single',
        'appears_on_slug' : 'singles/renegade-cavalcade',
        'first_released' : 'December 6, 2004',
        'first_released_raw' : '2004-12-06',
        'unreleased' : false
    },
    {
        'id' : 440,
        'title' : 'Wednesday Week',
        'alternative_titles' : '',
        'permalink' : '/lyrics/wednesday-week/',
        'songwriter' : 'Lyrics & Music: John O’Neill',
        'original_performer' : 'The Undertones',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 441,
        'title' : 'What Deaner Was Talking About',
        'alternative_titles' : '',
        'permalink' : '/lyrics/what-deaner-was-talking-about/',
        'songwriter' : 'Lyrics & Music: Ween',
        'original_performer' : 'Ween',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'A Life Less Ordinary - Single',
        'appears_on_slug' : 'singles/a-life-less-ordinary',
        'first_released' : 'October 27, 1997',
        'first_released_raw' : '1997-10-27',
        'unreleased' : false
    },
    {
        'id' : 442,
        'title' : 'What Ever Happened?',
        'alternative_titles' : '',
        'permalink' : '/lyrics/what-ever-happened/',
        'songwriter' : 'Lyrics & Music: Julian Casablancas',
        'original_performer' : 'The Strokes',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Little Infinity - EP',
        'appears_on_slug' : 'eps/little-infinity',
        'first_released' : 'June 16, 2012',
        'first_released_raw' : '2012-06-16',
        'unreleased' : false
    },
    {
        'id' : 443,
        'title' : 'What Goes On',
        'alternative_titles' : '',
        'permalink' : '/lyrics/what-goes-on/',
        'songwriter' : 'Lyrics & Music: Lou Reed',
        'original_performer' : 'The Velvet Underground',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 444,
        'title' : 'When I’m Tired',
        'alternative_titles' : 'Space Rock',
        'permalink' : '/lyrics/when-im-tired/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Wild Surf - Single',
        'appears_on_slug' : 'singles/wild-surf',
        'first_released' : 'November 23, 1998',
        'first_released_raw' : '1998-11-23',
        'unreleased' : false
    },
    {
        'id' : 445,
        'title' : 'Where I’m Calling From',
        'alternative_titles' : '',
        'permalink' : '/lyrics/where-im-calling-from/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'Grey Will Fade',
        'appears_on_slug' : 'solo/grey-will-fade',
        'first_released' : 'August 16, 2004',
        'first_released_raw' : '2004-08-16',
        'unreleased' : false
    },
    {
        'id' : 446,
        'title' : 'Where Is My Mind?',
        'alternative_titles' : '',
        'permalink' : '/lyrics/where-is-my-mind/',
        'songwriter' : 'Lyrics & Music: Black Francis',
        'original_performer' : 'Pixies',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'This Is Christmas',
        'appears_on_slug' : 'solo/this-is-christmas',
        'first_released' : 'November 23, 2011',
        'first_released_raw' : '2011-11-23',
        'unreleased' : false
    },
    {
        'id' : 447,
        'title' : 'Where Is Our Love Going?',
        'alternative_titles' : '',
        'permalink' : '/lyrics/where-is-our-love-going/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'A Life Less Ordinary - Single',
        'appears_on_slug' : 'singles/a-life-less-ordinary',
        'first_released' : 'October 27, 1997',
        'first_released_raw' : '1997-10-27',
        'unreleased' : false
    },
    {
        'id' : 448,
        'title' : 'Whiskey in the Jar',
        'alternative_titles' : '',
        'permalink' : '/lyrics/whiskey-in-the-jar/',
        'songwriter' : 'Traditional Irish folk tune',
        'original_performer' : 'The Dubliners',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 449,
        'title' : 'White',
        'alternative_titles' : '',
        'permalink' : '/lyrics/white/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'New Worlds',
        'appears_on_slug' : 'solo/new-worlds',
        'first_released' : 'October 16, 2009',
        'first_released_raw' : '2009-10-16',
        'unreleased' : false
    },
    {
        'id' : 450,
        'title' : 'Who You Drivin’ Now?',
        'alternative_titles' : '',
        'permalink' : '/lyrics/who-you-drivin-now/',
        'songwriter' : 'Lyrics & Music: Mark Arm, Steve Turner, Dan Peters and Matt Lukin',
        'original_performer' : 'Mudhoney',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Numbskull - EP',
        'appears_on_slug' : 'eps/numbskull',
        'first_released' : 'April 26, 1999',
        'first_released_raw' : '1999-04-26',
        'unreleased' : false
    },
    {
        'id' : 451,
        'title' : 'Whole Wide World',
        'alternative_titles' : '',
        'permalink' : '/lyrics/whole-wide-world/',
        'songwriter' : 'Lyrics & Music: Wreckless Eric',
        'original_performer' : 'Wreckless Eric',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 452,
        'title' : 'Why You Wanna?',
        'alternative_titles' : '',
        'permalink' : '/lyrics/why-you-wanna/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'Grey Will Fade',
        'appears_on_slug' : 'solo/grey-will-fade',
        'first_released' : 'August 16, 2004',
        'first_released_raw' : '2004-08-16',
        'unreleased' : false
    },
    {
        'id' : 453,
        'title' : 'Wild Surf',
        'alternative_titles' : '50’s Song, Fifties Song',
        'permalink' : '/lyrics/wild-surf/',
        'songwriter' : 'Lyrics: Tim Wheeler & Charlotte Hatherley. Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Nu-Clear Sounds',
        'appears_on_slug' : 'albums/nu-clear-sounds',
        'first_released' : 'October 5, 1998',
        'first_released_raw' : '1998-10-05',
        'unreleased' : false
    },
    {
        'id' : 454,
        'title' : 'Wipeout',
        'alternative_titles' : '',
        'permalink' : '/lyrics/wipeout/',
        'songwriter' : 'Lyrics & Music: Ash',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 455,
        'title' : 'Wish You Were Dead',
        'alternative_titles' : '',
        'permalink' : '/lyrics/wish-you-were-dead/',
        'songwriter' : 'Lyrics & Music: Ash',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 456,
        'title' : 'With or Without You',
        'alternative_titles' : '',
        'permalink' : '/lyrics/with-or-without-you/',
        'songwriter' : 'Music: U2. Lyrics: Bono',
        'original_performer' : 'U2',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 457,
        'title' : 'Won’t Be Saved',
        'alternative_titles' : 'I Won’t Be Saved',
        'permalink' : '/lyrics/wont-be-saved/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Meltdown',
        'appears_on_slug' : 'albums/meltdown',
        'first_released' : 'May 17, 2004',
        'first_released_raw' : '2004-05-17',
        'unreleased' : false
    },
    {
        'id' : 458,
        'title' : 'World Domination',
        'alternative_titles' : '',
        'permalink' : '/lyrics/world-domination/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Free All Angels',
        'appears_on_slug' : 'albums/free-all-angels',
        'first_released' : 'April 23, 2001',
        'first_released_raw' : '2001-04-23',
        'unreleased' : false
    },
    {
        'id' : 459,
        'title' : 'Wounded Sky',
        'alternative_titles' : '',
        'permalink' : '/lyrics/wounded-sky/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'The Deep Blue',
        'appears_on_slug' : 'solo/the-deep-blue',
        'first_released' : 'March 5, 2007',
        'first_released_raw' : '2007-03-05',
        'unreleased' : false
    },
    {
        'id' : 460,
        'title' : 'Wrong Notes',
        'alternative_titles' : '',
        'permalink' : '/lyrics/wrong-notes/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'New Worlds',
        'appears_on_slug' : 'solo/new-worlds',
        'first_released' : 'October 16, 2009',
        'first_released_raw' : '2009-10-16',
        'unreleased' : false
    },
    {
        'id' : 461,
        'title' : 'You Can’t Have It All',
        'alternative_titles' : '',
        'permalink' : '/lyrics/you-cant-have-it-all/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Twilight of the Innocents',
        'appears_on_slug' : 'albums/twilight-of-the-innocents',
        'first_released' : 'July 2, 2007',
        'first_released_raw' : '2007-07-02',
        'unreleased' : false
    },
    {
        'id' : 462,
        'title' : 'You Said Goodbye',
        'alternative_titles' : '',
        'permalink' : '/lyrics/you-said-goodbye/',
        'songwriter' : 'Lyrics & Music: Charlotte Hatherley',
        'original_performer' : '',
        'project_name' : 'Charlotte Hatherley',
        'song_type' : 'Original',
        'appears_on' : 'True Love',
        'appears_on_slug' : 'solo/true-love',
        'first_released' : 'May 16, 2017',
        'first_released_raw' : '2017-05-16',
        'unreleased' : false
    },
    {
        'id' : 463,
        'title' : 'You’ve Lost Your Shit',
        'alternative_titles' : '',
        'permalink' : '/lyrics/youve-lost-your-shit/',
        'songwriter' : 'Lyrics & Music: We Are Scientists',
        'original_performer' : 'We Are Scientists',
        'project_name' : '',
        'song_type' : 'Cover',
        'appears_on' : 'Unreleased',
        'appears_on_slug' : '',
        'first_released' : 'N/A',
        'first_released_raw' : '',
        'unreleased' : true
    },
    {
        'id' : 464,
        'title' : 'Zero Zero',
        'alternative_titles' : '',
        'permalink' : '/lyrics/zero-zero/',
        'songwriter' : 'Lyrics & Music: Tim Wheeler',
        'original_performer' : '',
        'project_name' : '',
        'song_type' : 'Original',
        'appears_on' : 'Get Ready - Single',
        'appears_on_slug' : 'singles/get-ready',
        'first_released' : 'December 4, 1995',
        'first_released_raw' : '1995-12-04',
        'unreleased' : false
    },
    {
        'id' : 465,
        'title' : 'Zombie Christmas',
        'alternative_titles' : '',
        'permalink' : '/lyrics/zombie-christmas/',
        'songwriter' : 'Lyrics & Music: Emma-Lee Moss & Tim Wheeler',
        'original_performer' : '',
        'project_name' : 'Emmy the Great & Tim Wheeler',
        'song_type' : 'Original',
        'appears_on' : 'This Is Christmas',
        'appears_on_slug' : 'solo/this-is-christmas',
        'first_released' : 'November 21, 2011',
        'first_released_raw' : '2011-11-21',
        'unreleased' : false
    }
];
