/**
 * Open external links in new window
 *
 * @version 1.0.0
 * @notes
 * We add `rel noopener  noreferrer` to each link to defend against
 * security issues.
 * @see https://developers.google.com/web/tools/lighthouse/audits/noopener
 * @example
 *
 * openExternalLinkInNewWindow();
 */
const openExternalLinkInNewWindow = () => {
    if (window.document.links) {
        const links = window.document.links;

        Array.from(links).forEach((link) => {
            if (link.hostname
                && link.hostname !== window.location.hostname
                && link.href.indexOf('mailto:') == -1
                && !link.hasAttribute('data-target-self')
                || link.hasAttribute('data-open-external')) {
                link.setAttribute('rel', 'noopener  noreferrer  external');
                link.setAttribute('target', '_blank');
            }
        });
    }
};

export default openExternalLinkInNewWindow;
