<template>
  <IntersectionObserver
      @intersect="imageHasIntersected">

    <transition :name="classTransition">

      <img
          v-if="imageIntersected"
          ref="image"
          :class="props.classImg"
          :alt="props.alt"
          :data-src="props.src"
          :srcset="props.srcset"
          :sizes="props.sizes"
          :width="props.width"
          :height="props.height"
          @load.once="imageLoaded = true">

    </transition>

  </IntersectionObserver>
</template>

<script setup lang="ts">
    import { ref, nextTick, useTemplateRef } from 'vue';
    import IntersectionObserver from '@components/IntersectionObserver.vue';
    import { ImgSrcsetProps } from '@type/ImgSrcset.types';

    const props = withDefaults(defineProps<ImgSrcsetProps>(), {
        alt: '',
        classTransition: 'v-fade-050'
    });

    const imageIntersected = ref(false);
    const imageLoaded = ref(false);
    const image = useTemplateRef<HTMLImageElement>('image');

    const imageHasIntersected = () => {

        // Setting `imageIntersected` to true will add the img element to the DOM
        imageIntersected.value = true;

        /**
         * We wait until the nextTick when the img element is in the DOM
         * and then set the src attribute with the data-src value and
         * finally remove the data-src attribute.
         *
         * This extra step is required to workaround a nasty bug in
         * Safari where both the src and srcset image are downloaded,
         * defeating the purpose of lazy loading. All other browsers work
         * correctly and this can be removed should Safari ever fix this
         * issue.
         *
         * @see https://github.com/vuejs/vue/issues/11357
         */
        nextTick(() => {
            if (!image.value) return;

            image.value.src = image.value.dataset.src || '';
            image.value.removeAttribute('data-src');
        });
    };
</script>
