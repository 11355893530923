<script>
    import SongLyricSearch from '@components/SongLyricSearch.vue';
    import ImgSrcset from '@components/ImgSrcset.vue';
    import LazyVideoPlayer from '@components/LazyVideoPlayer.vue';
    import PictureElement from '@components/PictureElement.vue';
    import openExternalLinkInNewWindow from '@utility/dom/open-external-link-in-new-window';

    export default {
        components : {
            ImgSrcset,
            LazyVideoPlayer,
            SongLyricSearch,
            PictureElement
        },

        mounted () {
            openExternalLinkInNewWindow();
        }
    };
</script>
